import React, { useState } from 'react'
import "./PZNPicker.css"
import Text, { TextWeight } from './Text'
import Button, { ButtonType } from './Button'
import { IonInput } from '@ionic/react'
import Colors from '../../Colors'
import { Product, ProductData } from '../../models/models'
import client from '../../api/client'
import { GET_PRODUCT_BY_PZN } from '../../api/queries'


interface PZNPickerProps {
    onProductSelected : Function
    onError: Function
}

const PZNPicker = ({onProductSelected, onError} : PZNPickerProps) => {
    const [pznNumber, setPznNumber] = useState("")

    async function queryProduct() {
        console.log("Query Product")
        if (pznNumber === "") {
            onError("Bitte füllen Sie die PZN aus.")
            return
        }

        const { errors, data } = await client.query<ProductData>({
            query: GET_PRODUCT_BY_PZN,
            variables: { pzn: pznNumber }
          })

          if (errors) {
            onError("Beim Laden des Produktes ist ein Fehelr aufgetreten.")
            return
          }

          let product: (Product|undefined) = undefined
          if (data) {
            product = data?.products.length > 0 ? data?.products[0] : undefined;
          }

          if (product) {
            onProductSelected(product)
            setPznNumber("")
          } else {
              // handle errror case
              onError("Das Produkt konnte nicht gefunden werden.")
          }
    }

    return (
        <div className="pzn-picker-container">
            <div className="pzn-picker-title">
            <Text weight={TextWeight.semibold}>per PZN hinzufügen:</Text>
            </div>
            <div className="pzn-picker-input">
                <IonInput
                    style={{textAlign:"center", fontWeight:TextWeight.normal, color:Colors.oceanBlue}}
                    color={Colors.oceanBlue}
                    type="tel"
                    maxlength={8}
                    required={true}
                    value = {pznNumber}
                    onIonChange={e => setPznNumber(e.detail.value!)} />
            </div>
            <Button kind={ButtonType.neutral} text="PZN übernehmen" top={20} bottom={20} onButtonClicked={async () => await queryProduct()}/>
        </div>
    );
}

export default PZNPicker