import React from 'react';
import { IonContent, IonPage, IonSpinner, isPlatform } from '@ionic/react';
import './CampaignsOverview.css';
import Header from '../../components/shared/Header';
import Text, {TextSize, TextWeight} from '../../components/shared/Text';
import CampaignCategoryTile from '../../components/shared/CampaignCategoryTile';
import { useQuery } from '@apollo/react-hooks';
import { CampaignCategoryData, CategoryType, CampaignCategory} from '../../models/models';
import { Link } from 'react-router-dom';
import { GET_CAMPAIGN_CATEGORIES } from '../../api/queries';
import Colors from '../../Colors';


const CampaignsCategoryOverview: React.FC = () => {
  const { loading, error, data } = useQuery<CampaignCategoryData>(GET_CAMPAIGN_CATEGORIES)
  function openCategory(category: CampaignCategory) {
    switch(category.categoryType) {
      case CategoryType.sale:
        return (
          <Link className={isPlatform("ios") ? "link-reset-incentive-ios":"link-reset-incentive"} key={category.id}
            to={{
              pathname: `/campaigns/${category.id}`,
              state: {title: category.title}
            }}>
            <CampaignCategoryTile campaignCategory={category}/>
          </Link>
        )
      case CategoryType.placement:
          return (
            <Link className={isPlatform("ios") ? "link-reset-incentive-ios":"link-reset-incentive"} key={category.id}
              to={{
                pathname: `/campaign-placement/${category.id}`,
                state: {title: category.title}
              }}>
            <CampaignCategoryTile campaignCategory={category}/>
          </Link>
          )
      case CategoryType.supply:
      case CategoryType.promotion:
      case CategoryType.display:
      case CategoryType.training:
        return (
          <Link className={isPlatform("ios") ? "link-reset-incentive-ios":"link-reset-incentive"} key={category.id}
            to={{
              pathname: `/campaign-categories/${category.id}`,
              state: {title: category.title}
            }}>
            <CampaignCategoryTile campaignCategory={category}/>
          </Link>
        )
      default:
        return (
          <Link className={isPlatform("ios") ? "link-reset-incentive-ios":"link-reset-incentive"} key={category.id}
            to={{
              pathname: `/campaign-categories/${category.id}`,
              state: {title: category.title}
            }}>
            <CampaignCategoryTile campaignCategory={category}/>
          </Link>
        )
    }

  }

  return (
    <IonPage>
      <IonContent>
        <Header key="campaign-overview" showInfoButton={true} imageUrl="/assets/images/HomeProductTeaser@3x.png"/>
        {
          loading ? (
            <div>
              <div className="spinner">
                <IonSpinner name="dots" />
              </div>
            </div>
          ) : <></>
        }
        {
          error ? (
            <span>
              { error.message }
            </span>) : <></>
        }
        <div className="title">
          <Text weight={TextWeight.light} size={TextSize.xl} color={Colors.brownishGrey}>
            Unsere Aktionen
          </Text>
        </div>
        <div className="subtitle">
          <Text weight={TextWeight.semibold} size={TextSize.l} color={Colors.brownishGrey}>
            Aktiv werden und Punkte sammeln
          </Text>
        </div>
        <div className="list">
        {
            data?.campaignCategories.sort((a, b) => a.order - b.order).map(category => (
              openCategory(category)
            ))
        }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CampaignsCategoryOverview;
