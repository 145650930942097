import { gql } from "apollo-boost";

/*
  Campaigns
*/
//#region Campaign Queries
export const GET_CAMPAIGN = gql`
  query getCampaign($id: ID!) {
    campaigns(id: $id) {
      id
      title
      subtitle
      description
      credits
      teaserImage
      startsAt
      expiresAt
      isInhouseTraining
      order
      trainingLink
      requestOffer
      campaignCategory {
        categoryType
      }
    }
  }
`

export const GET_CAMPAIGN_CATEGORIES = gql`
  query getCampaignCategories {
    campaignCategories {
      id
      title
      previewUrl
      credits
      categoryType
      order
    }
  }
`;

export const GET_CAMPAIGN_CATEGORY = gql`
  query getCampaignCategory($id: ID!) {
    campaignCategories(id: $id) {
      title
      id
      previewUrl
      description
      categoryType
      campaigns {
        id
        title
        subtitle
        description
        teaserImage
        isInhouseTraining
        startsAt
        expiresAt
        credits
        order
      }
    }
  }
`

//#endregion Campaign Queries


/*
  Products
*/
//#region Product Queries
export const GET_ALL_PRODUCTS = gql`
  query getProducts {
      products {
          id
          name
          productLine {
            name
          }
          priceCents
          pzn
          preview
          portion
      }
  }
`

export const GET_PRODUCT_BY_PZN = gql`
    query getProductByPzn($pzn: String!) {
        products(pzn: $pzn) {
            id
            name
            productLine {
              name
            }
            priceCents
            pzn
            preview
        }
    }
`
//#endregion Product Queries


/*
  User
*/
//#region User Queries

export const GET_CURRENT_USER_CREDITS = gql`
query getUser {
  currentUser {
    credits
    id
  }
}
`

export const GET_CURRENT_USER = gql`
  query getUser {
    currentUser {
        credits
        deliveryAddress {
          additionalInfo
          address
          city
          name
          zipcode
        }
        email
        firstName
        id
        lastName
        pharmacy {
          address
          city
          id
          name
          zipcode
        }
    }
  }
`
//#endregion User Queries


/*
  Incentive
*/
//#region Incentive Queries
export const GET_INCENTIVE_CATEGORIES = gql`
  query getIncentiveCategories {
    incentiveCategories {
      id
      title
      description
      image
    }
  }
`

export const GET_INCENTIVE_CATEGORY = gql`
  query getIncentiveCategory($id: ID!) {
    incentiveCategories(id: $id) {
      id
      title
      description
      image
      incentives {
        id
        credits
        product {
          name
          portion
          priceCents
          preview
        }
      }
    }
  }
`

//#endregion Messages Queries

/*
  Messages
*/
//#region Messages Queries
export const GET_MESSAGES = gql`
  query messages {
    messages { 
      title
      content
      publishedAt
      id
      teaserImage
    }
  }
`

export const GET_MESSAGE = gql`
  query messages($id: ID!) {
    messages(id: $id) { 
      title
      content
      publishedAt
      id
      teaserImage
    }
  }
`



//#endregion Messages Queries