import { useState, useEffect } from 'react';

// reference https://blog.bitsrc.io/writing-your-own-custom-hooks-4fbcf77e112e
// this is a very simplified version
function useLocalStorage(key: string): [(string | null), Function]{
  const [state, setState] = useState(localStorage.getItem(key))

  useEffect(() => {
    if (state) {
      console.log("Local String Storage Set: ", state)
      localStorage.setItem(key, state)
    }
  })

  return [state, setState]
}

export function useLocalNumberStorage(key: string): [(number | null), Function] {
  let itemString = localStorage.getItem(key)
  let itemNumber = null

  if (itemString !== null) {
    itemNumber = Number(itemString)
 }

  const [state, setState] = useState(itemNumber)

  useEffect(() => {
    if (state) {
      localStorage.setItem(key, String(state))
    }
  })

  return [state, setState]
}

export function useLocalObjectStorage(key: string): [(object | null), Function]{
  let itemString = localStorage.getItem(key)
  var itemObject = null
  if(itemString !== null) {
     itemObject = JSON.parse(itemString)
  }

  const [state, setState] = useState(itemObject)

  useEffect(() => {
    if (state) {
      localStorage.setItem(key, JSON.stringify(state))
    }
  })

  return [state, setState]
}


export default useLocalStorage