import React, { useState } from 'react';
import './InfoModal.css'
import { IonModal, IonContent } from '@ionic/react';
import Text, { TextSize, TextWeight } from './Text';
import Colors from '../../Colors';
import SegmentedText from './SegmentedText';

interface InfoModalProps {
  showModal: boolean
  onCloseClick: Function
}

const InfoModal = ({showModal, onCloseClick}: InfoModalProps) => {

  const [openText1, setOpenText1] = useState(false)
  const [openText2, setOpenText2] = useState(false)
  const [openText3, setOpenText3] = useState(false)
  const [openText4, setOpenText4] = useState(false)

  return (
  <IonModal isOpen={showModal} cssClass="fullscreen">
  
    <IonContent>
    <div className="faq-container">
      <div className="faq-close-button">
        <img src="/assets/images/Close.svg" alt="close" className="faq-close-button" onClick={() => onCloseClick()}/>
      </div>
      <div className="faq-title">
        <Text size={TextSize.xl} weight={TextWeight.light}>Feel Cetaphil<sup>®</sup> - Das Bonusprogramm für Apotheken Teams</Text>
      </div>
      <div className="faq-section">
        <SegmentedText isBottomLine={false}>
          <div className="fag-section-header-container">
            <div className="fag-section-header">
            <Text weight={TextWeight.semibold} color={openText1? Colors.darkLime : Colors.brownishGreyTwo}>
              Cetaphil<sup>®</sup> die starke Hautpflegemarke
            </Text>
            <img src={openText1 ? "/assets/images/ArrowGreen.svg" : "/assets/images/SelectionArrow.svg"} alt="close" className="" onClick={() => {setOpenText1(!openText1)}}/>
            </div>

            { openText1 ? (
            <div className="fag-section-content-container">
            <Text style={{marginBottom:10}}>
            Cetaphil<sup>®</sup> ist die dermatologische Pflege mit erstklassiger Qualität, sehr guter Verträglichkeit und hoher Anwenderzufriedenheit. 
            </Text> 
            <Text>In der Dermatologen-Empfehlung steht die Marke Cetaphil<sup>®</sup> auf Platz 1*. Neben der Basispflege bietet Cetaphil<sup>®</sup> Deinen Kunden als Problemlöser auch Spezialpflege für unterschiedliche Hautzustände und hochwirksamen Sonnenschutz.</Text>
            <Text style={{marginTop:20}} size={TextSize.s}>*Umfrage i.A. von Galderma bei 518 niedergelassenen Dermatologen (2018, Marpinion GmbH, Oberhaching)</Text> </div>) : (
            <></>
            )
            }
          </div>
        </SegmentedText>
        <SegmentedText isBottomLine={false}>
          <div className="fag-section-header-container">
            <div className="fag-section-header">
            <Text weight={TextWeight.semibold} color={openText2? Colors.darkLime : Colors.brownishGreyTwo}>
              Warum Feel Cetaphil<sup>®</sup>?
            </Text>
            <img src={openText2 ? "/assets/images/ArrowGreen.svg" : "/assets/images/SelectionArrow.svg"} alt="close" className="" onClick={() => {setOpenText2(!openText2)}}/>
            </div>

            { openText2 ? (
            <div className="fag-section-content-container">
              <Text style={{marginBottom:10}}>
              Das Bonusprogramm „Feel Cetaphil<sup>®</sup>“ möchte Dir mehr Motivation und Spaß bei kompetenter Kundenberatung und Kaufempfehlungen vermitteln.
              </Text>
              <Text style={{marginBottom:10}}>Wer empfehlen will muss fühlen: Nur wer von einem Produkt überzeugt ist, kann es auch guten Gewissens weiterempfehlen.</Text>
              <Text>Deine eigenen Erfahrungen sorgen für authentische & überzeugende Beratung inder Apotheke.</Text>
               </div>) : (
              <></>
            )
            }
          </div>
        </SegmentedText>
        <SegmentedText isBottomLine={false}>
          <div className="fag-section-header-container">
            <div className="fag-section-header">
            <Text weight={TextWeight.semibold} color={openText3? Colors.darkLime : Colors.brownishGreyTwo}>
              Wie funktioniert Feel Cetaphil<sup>®</sup>?
            </Text>
            <img src={openText3 ? "/assets/images/ArrowGreen.svg" : "/assets/images/SelectionArrow.svg"} alt="close" className="" onClick={() => {setOpenText3(!openText3)}}/>
            </div>

            { openText3 ? (
              <div className="fag-section-content-container">
            <Text style={{marginBottom:10}}>
            Empfehle/ Verkaufe Cetaphil<sup>®</sup>* oder nimm an exklusiven Schulungen teil und erhalte persönliche Bonuspunkte. Auch jede Team-Aktivität für Cetaphil<sup>®</sup> wie z.B. Freiwahloder Schaufensterplatzierungen sind Aktivitäten, mit denen Du Bonuspunkte sammeln kannst.
            </Text> 
            <Text style={{marginBottom:10}}>
            Alle Punkte lassen sich schnell & unkompliziert für Cetaphil<sup>®</sup>-Produkte einlösen. Wähle Dein(e) Produkt(e) entsprechend Deiner verfügbaren Punktezahl aus und innerhalb von 5 Werktagen erhältst Du Dein persönliches Produktpaket.
            </Text> 
            <Text weight={TextWeight.semibold} style={{marginBottom:10}}>
            Besonders schön: innerhalb der ersten 4 Wochen nach Anmeldung erhältst Du für alle dokumentierten Aktivitäten die doppelte Punktzahl. Also los, verliere keine Zeit!
            </Text> 
            <Text style={{marginTop:20}} size={TextSize.s}>
            *ausgenommen: Medizinprodukt Cetaphil<sup>®</sup> PRO RednessControl Creme zur Symptombehandlung
            </Text> 
            </div>) : (
              <></>
            )
            }
          </div>
        </SegmentedText>
        <SegmentedText>
          <div className="fag-section-header-container">
            <div className="fag-section-header">
            <Text weight={TextWeight.semibold} color={openText4? Colors.darkLime : Colors.brownishGreyTwo}>
              Wie punktet man mit Feel Cetaphil<sup>®</sup>?
            </Text>
            <img src={openText4 ? "/assets/images/ArrowGreen.svg" : "/assets/images/SelectionArrow.svg"} alt="close" className="" onClick={() => {setOpenText4(!openText4)}}/>
            </div>

            { openText4 ? (
            <div className="fag-section-content-container">
            <Text style={{marginBottom:40}}>
            Jede Empfehlung, jede Schulung & jede Aktion für Cetaphil<sup>®</sup> punktet, durch Dokumentation.
            </Text> 
            <Text weight={TextWeight.semibold} style={{marginBottom:5}}>
            Empfehlungen/Abverkauf:
            </Text> 
            <Text style={{marginBottom:40}}>
            Gib einfach die jeweilige PZN und Mengen der abverkauften Cetaphil<sup>®</sup> Produkte direkt ein oder nutze das Dropdown-Menü dafür und „zack“ werden die Punkte automatisch Deinem Mitgliedskonto gutgeschrieben. 
            Als Nachweis bitten wir Dich, einen Auszug aus dem Kassensystem mit Deinen Abverkäufen zu fotografieren und hochzuladen.
            </Text> 
            <Text weight={TextWeight.semibold} style={{marginBottom:5}}>
            Schulungen:
            </Text> 
            <Text style={{marginBottom:40}}>
            Nach Teilnahme an einer Schulung erhältst Du einen individuellen Zahlencode, den Du ebenfalls per App oder online eingibst und so adhoc Deine Punkte-Gutschrift erhältst.
            </Text> 
            <Text weight={TextWeight.semibold} style={{marginBottom:5}}>
            Aktionen:
            </Text> 
            <Text>
            Jede Team-Aktion für Cetaphil<sup>®</sup> wie bspw. Freiwahlplatzierung oder Schaufenster-Dekoration kannst Du einfach mittels eines hochgeladenen Fotos dokumentieren.
            </Text> 
            </div>) : (
              <></>
            )
            }
          </div>
        </SegmentedText>
      </div>
    </div>
    </IonContent>
  </IonModal>

  )
}

export default InfoModal;