import React, { useState } from 'react'
import "./ProductImagePicker.css"
import Button, { ButtonType } from './Button'
import { IonAlert, isPlatform } from '@ionic/react'

import { Plugins, CameraResultType, CameraSource, } from '@capacitor/core';

const { Camera } = Plugins;

interface ProductImagerPickerProps {
    onImageChosen: Function
}

const ProductImagePicker = ({ onImageChosen }: ProductImagerPickerProps) => {

        const [showPrivacyAlert, setShowPrivacyAlert] = useState(false);
        const [takePicture, setTakePicture] = useState(false);


        async function retrieveImage() {
            setShowPrivacyAlert(false)
            console.log("picture")
            if (takePicture) {
                const image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: false,
                    resultType: CameraResultType.DataUrl,
                    source: CameraSource.Camera
                    });
                console.log("image : ", image)
                onImageChosen(image.dataUrl)
            } else {
                const image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: false,
                    resultType: CameraResultType.DataUrl,
                    source: CameraSource.Photos})
                onImageChosen(image.dataUrl)
            }
        }

        function onChangeImage(fileList : FileList | null) {

            console.log("Ausgabe: e: ", fileList)
            if (fileList != null) {
                let file = fileList[0];

                let reader = new FileReader();                
                reader.onloadend = () => {
                    console.log("Ausgabe: reader.result: ", reader.result)
                    onImageChosen(reader.result)
                }
                reader.readAsDataURL(file)
            }
            
          }

          function isNative() {
            //   console.log('Platform: ', getPlatforms())
              if (isPlatform('desktop') || isPlatform('mobileweb')) {
                  return false
              }
            return true
          }

        return (


            <div className="product-image-picker-container">

                <IonAlert
                    isOpen={showPrivacyAlert}
                    onDidDismiss={() => setShowPrivacyAlert(false)}
                    header={'Hinweis'}
                    message={'Bitte darauf achten, dass keine persönlichen Daten auf dem Foto zu sehen sind.'}
                    buttons={[
                        {
                          text: 'Abbrechen',
                          role: 'cancel',
                        },
                        {
                          text: 'Ok',
                          handler: retrieveImage
                        }
                    ]}
                />

                <Button kind={ButtonType.neutral} text="Foto aufnehmen" top={20} bottom={20} onButtonClicked={() => {
                    setTakePicture(true)
                    setShowPrivacyAlert(true)

                }} ></Button>

                <Button kind={isNative() ? ButtonType.neutral : ButtonType.upload} text={isNative() ?  "Aus Mediathek" : "Bild auswählen"} top={20} bottom={20} onButtonClicked={
                    isNative() ? 
                    (
                        ()=>{                    
                            setTakePicture(false)
                            setShowPrivacyAlert(true)
                        }
                        ) :
                    (
                        (files : FileList) => {
                            onChangeImage(files)
                        })
                    }
                ></Button>

            </div>
        )



}

export default ProductImagePicker