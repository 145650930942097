import React, { useState, useContext } from 'react';
import Header from '../components/shared/Header';
import './Login.css'
import { IonInput, IonToast, IonLoading, IonPage, IonContent } from '@ionic/react';
import Text, { TextSize, TextWeight } from '../components/shared/Text';
import Colors from '../Colors';
import Button from '../components/shared/Button';
import { useMutation } from '@apollo/react-hooks';
import { SIGN_IN_MUTATION } from '../api/mutations';
import { UserContext } from '../contexts/UserContext';
import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;

interface LoginProps {
  onRegistrationClicked: Function
}

const Login = ({ onRegistrationClicked }: LoginProps) => {
  const [eMail, setEMail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(<></>)
  const [mutate] = useMutation(SIGN_IN_MUTATION)
  const [showLoading, setShowLoading] = useState(false);
  const userContext = useContext(UserContext)

  function onError(message: string, duration = 1000) {
    setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
    // make sure that we can reuse our toast
    setTimeout(() => {
        setError(<></>)
    }, duration)
  }

  async function onSubmitLogin() {
    if (eMail.length < 3) {
      onError("Bitte geben Sie ihre E-Mail Adresse ein")
      return
    }

    if (password.length < 8) {
      onError("Bitte geben Sie ihre Passwort ein. Es muss mindestens 8 Zeichen lang sein.")
      return
    }

    try {
      setShowLoading(true)
      const result = await mutate({
        variables: {
          email: eMail,
          password
        }
      })
      userContext.setToken(result.data.signInUser.token)
    } catch (error) {
      setShowLoading(false)
      console.log("Error: ", error)
      onError("Beim Login ist ein Fehler aufgetreten bitte überprüfen sie Ihre Daten.", 3000)
    }
  }

  async function onPasswordResetClick() {
    await Browser.open({ url: 'https://galderma.herokuapp.com/password_resets/new' });
  }

  return(
    <IonPage>
    <IonContent>
      <Header key="login" />
      <div className="login-background-container">
        <div className="login-container">
          <div className="login-text-input">
            <IonInput
              style={{
                textAlign:"left",
                fontWeight: "600",
                fontSize: TextSize.m,
                color: Colors.brownGrey,
                fontFamily: "OpenSans"
              }}
              color={Colors.oceanBlue}
              type="email"
              required={true}
              value={eMail}
              placeholder="E-Mail"
              onIonChange={(e) => setEMail(e.detail.value!)} />
          </div>
          <div className="login-text-input">
            <IonInput
              style={{
                textAlign:"left",
                fontWeight: "600",
                fontSize: TextSize.m,
                color: Colors.brownGrey,
                fontFamily: "OpenSans"
              }}
              color={Colors.oceanBlue}
              type="password"
              required={true}
              value={password}
              placeholder="Passwort"
              onIonChange={(e) => setPassword(e.detail.value!)} />
          </div>
          <div className="login-button">
            <Button text="Einloggen" onButtonClicked={onSubmitLogin} />
          </div>
          <div className="alternatives-container">
            <div onClick={() => {onRegistrationClicked()}}>
              <Text weight={TextWeight.normal} size={TextSize.l} color={Colors.oceanBlue} style={{textAlign: "center"}}>
                Jetzt Registrieren!
              </Text>
            </div>
            <div className="login-seperation-line"/>
            <div onClick={ () => {onPasswordResetClick()}}>
              <Text weight={TextWeight.normal} size={TextSize.l} color={Colors.oceanBlue} style={{textAlign: "center"}}>
                Passwort vergessen?
              </Text>
            </div>
          </div>
          { error }
          <IonLoading
            isOpen={showLoading}
            message={'Sie werden eingeloggt...'}
          />
          </div>
      </div>
    </IonContent>
    </IonPage>
  )
}

export default Login;