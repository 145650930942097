import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonSpinner, IonLoading, IonToast} from '@ionic/react'
import Header from "../../components/shared/Header"
import "./CampaignDisplaysDetails.css"
import { RouteComponentProps } from 'react-router'
import { Campaign, CategoryType } from '../../models/models'
import { GraphQLError } from 'graphql'
import Text, { TextSize, TextWeight } from '../../components/shared/Text'
import Button, { ButtonType } from '../../components/shared/Button'
import SegmentedText from '../../components/shared/SegmentedText'
import { Link } from 'react-router-dom'
import moment from 'moment'
import client from '../../api/client'
import { GET_CAMPAIGN } from '../../api/queries'
import Colors from '../../Colors'
import { useMutation } from '@apollo/react-hooks'
import { REQUEST_OFFER_MUTATION } from '../../api/mutations'

interface CampaignDisplaysDetailsRouterProps {
    id: string;
  }

interface CampaignDisplaysDetailsProps extends RouteComponentProps<CampaignDisplaysDetailsRouterProps> {
    location: any
  }

  interface CampaignDisplaysDetailsState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
  }

const CampaignDisplaysDetails = ({match, location} : CampaignDisplaysDetailsProps) => {

    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }
    const [state, setState] = useState<CampaignDisplaysDetailsState>({loading: true, errors: undefined, campaign: undefined})
    const [mutate] = useMutation(REQUEST_OFFER_MUTATION)
    const [showLoading, setShowLoading] = useState(false);
    const [status, setStatus] = useState(<></>)

    async function loadCampaignDetails() {
        try {
          
          const { loading, errors, data } = await client.query({
            query: GET_CAMPAIGN,
            variables: { id },
            fetchPolicy: "network-only"
          })

          let campaign: (Campaign|undefined) = undefined
          if (data) {
            campaign = data.campaigns.length > 0 ? data.campaigns[0] : undefined;
          }

          setState({ campaign, errors, loading })
        } catch {
          setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }
      }

      const startLoading = () => {
        loadCampaignDetails()
      }

      useEffect(startLoading, [id])

      const onStatus = (message: string, duration = 1000, color="danger") => {
        setStatus(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={color}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
          setStatus(<></>)
        }, duration)
      }

      const requestOfferButtonText = () => {
        if (state.campaign?.campaignCategory.categoryType === CategoryType.supply) { 
          return "Bevorratung"
        } else {
          return "Angebot"
        }
      }

       const requestOffer = async() => {
        const campainId = state.campaign?.id;

        try {
          setShowLoading(true)
          await mutate({
              variables: {
                  campaignId: campainId,
              }
          })
          setShowLoading(false)
          onStatus(requestOfferButtonText() + " angefordert", 2000, "success")
      } catch (error) {
        console.log("error: "+error)
          setShowLoading(false)
          onStatus("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
      }
      }

    return (

        <IonPage>
            <IonContent>
            <Header
                key="campaign-hv-displays"
                showInfoButton={false}
                enableBackNavigation={true}
                compactMode={true}
                label={title}>
              {
                state.loading? (
                  <div className="spinner">
                    <IonSpinner name="dots" />
                  </div>
                ) : (
                  <div>
                    <img src={state.campaign?.teaserImage} alt="" />
                  </div>
                )
              }
            </Header>
            <div className="campaign-display-container">
              <div className="campaign-display-title">
                  <Text size={TextSize.xl} weight={TextWeight.light} color={Colors.oceanBlue}>{state.campaign?.title}</Text>
                  <Text size={TextSize.xl} weight={TextWeight.light} color={Colors.oceanBlue}>{state.campaign?.subtitle}</Text>
              </div>
              <div className="campaign-display-description">
                  <Text>{state.campaign?.description}</Text>
              </div>
                

                <div className="campaign-display-segments-container">
                    <SegmentedText isBottomLine={false}>
                        <div className="campaign-display-segments-text">
                            <Text>Zeitraum:</Text>
                            {
                              state.campaign?.startsAt !== undefined ? (
                                <Text>{moment(state.campaign?.startsAt).format('DD.MM.YY')} - {moment(state.campaign?.expiresAt).format('DD.MM.YY')}</Text> 
                              ):(
                                <Text>unbegrenzt</Text>
                              )
                            } 
                        </div>
                    </SegmentedText>
                    <SegmentedText>
                        <div className="campaign-display-segments-text">
                            <Text>Anzahl Punkte:</Text>
                            <Text>{state.campaign?.credits}</Text>
                        </div>
                    </SegmentedText>
                </div>
                <div className="campaign-display-submit-button">
                    <Link className="link-reset" key="campaign-display-proof"
                    to={{
                      pathname: `/campaign-display-proof/${state.campaign?.id}`,
                      state: {title: title}
                    }}>
                      <Button text="Nachweis einreichen" onButtonClicked={()=>{}} bottom={40}></Button>
                    </Link>
                </div>
                {
                  state.campaign?.requestOffer ? (
                    <div>
                      <div className="campaign-display-info-text">
                        <Text weight={TextWeight.semibold}>Ich interessiere mich für das</Text>
                        <Text weight={TextWeight.semibold}>{state.campaign?.title}</Text>
                        <Text weight={TextWeight.semibold}>{state.campaign?.subtitle}</Text>
                      </div>
                      <div className="campaign-display-info-button">
                        <Button kind={ButtonType.neutral} text={requestOfferButtonText() + " anfordern"} onButtonClicked={requestOffer}></Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                }

            </div>
              { status }
              <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
          </IonContent>

        </IonPage>



    )


}

export default CampaignDisplaysDetails