import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonSpinner } from '@ionic/react'
import { Campaign } from '../../models/models'
import { GraphQLError } from 'graphql'
import { RouteComponentProps } from 'react-router'
import Header from '../../components/shared/Header'
import './CampaignProofDone.css'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'

import SegmentedText from '../../components/shared/SegmentedText'
import moment from 'moment'
import client from '../../api/client'
import { GET_CAMPAIGN, GET_CURRENT_USER_CREDITS } from '../../api/queries'
import Colors from '../../Colors'


interface CampaignProofDoneRouterProps {
    id: string;
  }

interface CampaignProofDoneProps extends RouteComponentProps<CampaignProofDoneRouterProps> {
    location: any
}

interface CampaignProofDoneState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
    totalCredits?: number
}

const CampaignProofDone = ({match, location}: CampaignProofDoneProps) => {
    const id = match.params.id;
    let title = ""
    var credits = 0
    if (location.state) {
        title = location.state.title
        if(location.state.credits){
            credits = location.state.credits
        }
        
    }
    
    const [state, setState] = useState<CampaignProofDoneState>(
        {
            loading: true,
            errors: undefined,
            campaign: undefined,
            totalCredits: undefined
        }
    )

    async function loadData() {
      const { loading, errors, data } = await client.query({
        query: GET_CAMPAIGN,
        variables: { id },
        fetchPolicy: "network-only"
      })

      let campaign: (Campaign|undefined) = undefined
      if (data) {
        campaign = data?.campaigns.length > 0 ? data?.campaigns[0] : undefined;
      }

      const userQueryResult  = await client.query({
        query: GET_CURRENT_USER_CREDITS,
        fetchPolicy: "network-only"
      })
            
      setState({campaign, errors, loading, totalCredits: userQueryResult.data?.currentUser.credits })
    }

    const startLoading = () => {
        loadData()
    }

    useEffect(startLoading, [id])

    return (

        <IonPage>
            <IonContent>
                <Header
                key="campaign-proof-done"
                showInfoButton={false}
                enableBackNavigation={false}
                compactMode={true}
                label={title}>
                    <div style={{height: 40}}></div>
                </Header>

                <div className="campaign-proof-done-container">
                    <div className="campaign-proof-done-title">
                        <Text color={Colors.darkLime} weight={TextWeight.light} size={TextSize.xl}>Ihr Nachweis wurde eingereicht</Text>
                    </div>
                    <SegmentedText top={40} isBottomLine={false}>
                        <div className="campaign-proof-done-segmented-text-container">
                            <Text style={{marginRight:5}}>Für: </Text>
                            {
                                state.campaign ?
                                    <div className="campaign-proof-done-segmented-title">
                                    <Text>{state.campaign?.title}</Text>
                                    <Text style={{marginLeft:5}}>{state.campaign?.subtitle}</Text>
                                    </div>
                                : <IonSpinner name="dots" />
                            }
                        </div>
                    </SegmentedText>
                    <SegmentedText  isBottomLine={false}>
                        <div className="campaign-proof-done-segmented-text-container">
                            <Text>Zeitraum: </Text>
                            <Text>{moment(state.campaign?.startsAt).format("DD.MM.YYYY")} - {moment(state.campaign?.expiresAt).format("DD.MM.YYYY")}</Text>
                        </div>
                    </SegmentedText>
                    <SegmentedText  isBottomLine={false}>
                        <div className="campaign-proof-done-segmented-text-container">
                            <Text>Gutgeschriebene Punkte: </Text>
                            {
                                state.campaign ?
                                    <Text>{credits === 0 ? state.campaign.credits : credits}</Text>
                                : <IonSpinner name="dots" />
                            }
                        </div>
                    </SegmentedText>
                    <SegmentedText>
                        <div className="campaign-proof-done-segmented-text-container">
                            <Text>Aktueller Punktestand: </Text>
                            {
                                state.totalCredits ?
                                    <Text>{state.totalCredits}</Text>
                                : <IonSpinner name="dots" />
                            }

                        </div>
                    </SegmentedText>
                </div>

                <div className="campaign-proof-done-check-container">
                    <img className="" src="/assets/images/Check.svg" alt="Check"/>
                </div>

            </IonContent>
        </IonPage>



    )

}

export default CampaignProofDone