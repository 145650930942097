import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonContent,
  IonModal,
  IonBadge,
  isPlatform
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import CampaignsCategoryOverview from './pages/campaign/CampaignsOverview';
import CampaignListing from './pages/campaign/CampaignListing';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


/* Theme variables */
import './theme/variables.css';

/* Import additional CSS */
import './App.css';

/* Apollo Setup */
import { ApolloProvider } from '@apollo/react-hooks';
import CampaignDetails from './pages/campaign/CampaignDetails';
import CampaignSale from './pages/campaign/CampaignSale';
import CampaignDisplaysDetails from './pages/campaign/CampaignDisplaysDetails';
import CampaignDisplaysProof from './pages/campaign/CampaignDisplaysProof';
import CampaignProofDone from './pages/campaign/CampaignProofDone';
import CampaignTrainingDetails from './pages/campaign/CampaignTrainingDetails';
import CampaignTrainingProof from './pages/campaign/CampaignTrainingProof';
import CampaignPlacement from './pages/campaign/CampaignPlacement';
import client from './api/client';
import CampaignPlacementProof from './pages/campaign/CampaignPlacementProof';
import IncentiveOverview from './pages/incentive/IncentiveOverview';
import IncentiveDetails from './pages/incentive/IncentiveDetails';
import UserContextProvider, { UserContext } from './contexts/UserContext';
import CartContextProvider from './contexts/CartContext';
import IncentiveCart from './pages/incentive/IncentiveCart';
import Profile from './pages/Profile';
import News from './pages/News';
import Registration from './pages/Registration';
import NewsDetails from './pages/NewsDetails';
import UserManagement from './pages/UserManagement';
import NewsCountContextProvider, { NewsCountContext } from './contexts/NewsCountContext';

defineCustomElements(window);

const AppContent = () => {
  const userContext = useContext(UserContext);
  const newsCountContext = useContext(NewsCountContext);

  return (
    <>
      {
        userContext.isLoggedIn === false ? (
          <IonContent>
            <IonModal isOpen={userContext.isLoggedIn === false} cssClass="fullscreen">
              <UserManagement />
            </IonModal>
          </IonContent>
        ) : <></>
      }
      <IonTabs>
        <IonRouterOutlet>

            <Route key={`root-${userContext.isLoggedIn}`} path="/" exact={true} render={
              props => { return userContext.isLoggedIn ? <CampaignsCategoryOverview/> : <UserManagement/> }
            } />
            <Route key={'register'} path="/register" component={Registration} exact={true} />
            <Route key={`campaigns-overview-${userContext.isLoggedIn}`} path="/campaigns-overview" component={CampaignsCategoryOverview} exact={true}/>
            <Route key={`campaigns-categories-${userContext.isLoggedIn}`} path="/campaign-categories/:id" component={CampaignListing} exact={true} />
            <Route key={`campaigns-${userContext.isLoggedIn}`} path="/campaigns/:id" component={CampaignDetails} exact={true} />
            <Route key={`campaign-sale-${userContext.isLoggedIn}`}path="/campaign-sale/:id" component={CampaignSale} exact={true} />
            <Route key={`campaign-display-${userContext.isLoggedIn}`} path="/campaign-display/:id" component={CampaignDisplaysDetails} exact={true} />
            <Route key={`campaign-display-proof-${userContext.isLoggedIn}`} path="/campaign-display-proof/:id" component={CampaignDisplaysProof} exact={true} />
            <Route key={`campaign-display-proof-done-${userContext.isLoggedIn}`} path="/campaign-proof-done/:id" component={CampaignProofDone} exact={true} />
            <Route key={`campaign-display-${userContext.isLoggedIn}`} path="/campaign-training/:id" component={CampaignTrainingDetails} exact={true} />
            <Route key={`campaign-training-proof-${userContext.isLoggedIn}`} path="/campaign-training-proof/:id" component={CampaignTrainingProof} exact={true} />
            <Route key={`campaign-placement-${userContext.isLoggedIn}`} path="/campaign-placement/:id" component={CampaignPlacement} exact={true} />
            <Route key={`campaign-placement-proof-${userContext.isLoggedIn}`} path="/campaign-placement-proof/:id" component={CampaignPlacementProof} exact={true} />
            <Route key={`incentive-overview-${userContext.isLoggedIn}`} path="/incentive-overview" component={IncentiveOverview} exact={true} />
            <Route key={`incentive-details-${userContext.isLoggedIn}`} path="/incentive-details/:id" component={IncentiveDetails} exact={true} />
            <Route key={`incentive-overview-${userContext.isLoggedIn}`} path="/incentive-overview" component={IncentiveOverview} exact={true} />
            <Route key={`incentive-details-${userContext.isLoggedIn}`} path="/incentive-details/:id" component={IncentiveDetails} exact={true} />
            <Route key={`incentive-cart-${userContext.isLoggedIn}`} path="/incentive-cart/:id" component={IncentiveCart} exact={true} />
            <Route key={`profile-${userContext.isLoggedIn}`} path="/profile" component={Profile} exact={true}  />
            <Route key={`news-${userContext.isLoggedIn}`} path="/news" component={News} exact={true}  />
            <Route key={`news-details-${userContext.isLoggedIn}`} path="/news-details/:id" component={NewsDetails} exact={true}  />
        </IonRouterOutlet>
        {
          userContext.isLoggedIn === true ? (
            <IonTabBar slot="bottom">
              <IonTabButton tab="tab1" href="/campaigns-overview">
                <IonIcon src="/assets/images/icon_campaign_noFill.svg"/>
                <IonLabel>Aktion</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab2" href="/news">
                {newsCountContext.newsCount !== undefined && newsCountContext.newsCount !== null && newsCountContext.newsCount > 0 ?
                (<IonBadge color="secondary">{newsCountContext.newsCount}</IonBadge>) : (<></>)
                }
                <IonIcon src="/assets/images/icon_news_noFill.svg" />
                <IonLabel>News</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab3" href="/incentive-overview">
                <IonIcon src="/assets/images/icon_incentive_noFill.svg" />
                <IonLabel>Prämien</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab4" href="/profile" >
                <IonIcon src="/assets/images/icon_profile_noFill.svg" />
                <IonLabel>Profil</IonLabel>
              </IonTabButton>
            </IonTabBar>
        ) : (<IonTabBar></IonTabBar>)
      }
      </IonTabs>
    </>
  )
}

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <CartContextProvider>
        <NewsCountContextProvider>
        <IonApp className={isPlatform('desktop') === true ? ("frame") : ("")}>
        {(isPlatform("desktop")) === true ?
                (<>
                  <div className="phone-speaker"></div>
                  <div className="phone-camera"></div>
                  </>
                ) : (<></>)
        }
          <IonReactRouter>
            <AppContent />
          </IonReactRouter>
        </IonApp>
        </NewsCountContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  )
};

export default App;
