import React, { useState } from 'react'
import './IncentiveOverview.css'
import { IonPage, IonContent, IonSpinner, useIonViewDidEnter, IonFooter, isPlatform } from '@ionic/react'
import Header from '../../components/shared/Header'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'
import Colors from '../../Colors'
import {IncentiveCategoryData, User} from '../../models/models'
import {GET_INCENTIVE_CATEGORIES, GET_CURRENT_USER} from '../../api/queries'
import { Link, useHistory } from 'react-router-dom'
import IncentiveCategoryTile from '../../components/shared/IncentiveCategoryTile'
import client from '../../api/client'
import { GraphQLError } from 'graphql'


interface IncentiveOverviewState {
    categoriesData?: IncentiveCategoryData
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
    user?: User
}

const IncentiveOverview = () => {

    const history = useHistory();

    const [state, setState] = useState<IncentiveOverviewState> (
        {
            categoriesData: undefined,
            errors: undefined,
            loading: true,
            user: undefined
        }
    )


    async function loadData() {

        const { loading, errors, data } = await client.query({
            query: GET_INCENTIVE_CATEGORIES,
            fetchPolicy: "network-only"
        })

        let categoriesData : (IncentiveCategoryData | undefined) = undefined
        if(data) {
            categoriesData = data
        }

        const userQueryResult = await client.query({
            query: GET_CURRENT_USER,
            fetchPolicy: "network-only"
        })

        setState({categoriesData, errors, loading, user: userQueryResult.data?.currentUser})
    }

    useIonViewDidEnter(() => {
        loadData()
    })

    return (
        <IonPage>
            <IonContent>
                <Header key="incentive-overview" showInfoButton={true} imageUrl="/assets/images/HomeProductTeaser@3x.png"/>
                <div className="incentive-overview-container">
                            {
                    state.loading ? (
                        <div>
                        <div className="spinner">
                            <IonSpinner name="dots" />
                        </div>
                        </div>
                    ) : <></>
                    }
                    <div className="incentive-title">
                    <Text weight={TextWeight.light} size={TextSize.xl} color={Colors.brownishGrey}>
                        Dein Punktestand
                    </Text>
                    </div>
                    <div className="incentive-credits">
                    <Text size={TextSize.xxl} color={Colors.oceanBlue}>
                        {state.user?.credits}
                    </Text>
                    </div>
                    <div className="incentive-text">
                    <Text weight={TextWeight.light} size={TextSize.xl} color={Colors.brownishGrey}>
                        Unsere Prämien für Dich
                    </Text>
                    </div>
                    <div className="incentive-list">
                        {
                            
                        state.categoriesData?.incentiveCategories?.map(category => (
                            <Link className={isPlatform("ios") ? "link-reset-incentive-ios":"link-reset-incentive" } key={category.id}
                            to={{
                              pathname: `/incentive-details/${category.id}`,
                              state: {title: "Cetaphil<sup>®</sup> Prämien"}
                            }}>
                            <IncentiveCategoryTile incentiveCategory={category}/>
                          </Link>
                       
                        ))
                        }
                    </div>
                </div>
            </IonContent>
            
            {
            // eslint-disable-next-line
            state.user?.deliveryAddress?.address.length ?? 0 > 0 ? (<></>) : (
            <IonFooter>
              <div onClick={()=>{history.push(`/profile/`)}}>
              <div className="delivery-adress-info-container">
                <Text size={TextSize.s} weight={TextWeight.semibold} color={Colors.white} style={{textTransform:"uppercase", margin:20}}>Bitte gebe deine Lieferadresse an, sonst werden deine Prämien an die Apotheke verschickt.</Text>
                </div>
              </div>                
            </IonFooter>
            )
            }
        </IonPage>
    )

}

export default IncentiveOverview