import React, { useState } from 'react'
import Login from './Login'
import Registration from './Registration'
import { IonToast } from '@ionic/react'

const UserManagement = () => {
  const [showRegistration, setShowRegistration] = useState(false)
  const [success, setSuccess] = useState(<></>)
  function onSuccess(message: string, duration = 1000) {
    setSuccess(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"success"}/>)
    // make sure that we can reuse our toast
    setTimeout(() => {
        setSuccess(<></>)
    }, duration)
  }

  const onRegistrationClicked = () => setShowRegistration(true)
  const onRegistrationFinished = () => {
    setShowRegistration(false)
    onSuccess("Ihre Registrierung war erfolgreich")
  }

  const onBackToLogin = () => setShowRegistration(false)

  return (
    <>
      {
        showRegistration ?
          <Registration onRegistrationFinished={onRegistrationFinished} onBackToLogin={onBackToLogin} />
        :
          <Login onRegistrationClicked={onRegistrationClicked} />
      }
      { success }
    </>
  )
}

export default UserManagement