import React, {CSSProperties} from 'react'
import Text, { TextWeight } from './Text'
import Colors from '../../Colors';
import './Button.css'

export enum ButtonType {
    positiv,
    neutral,
    deactivated,
    upload
}

interface ButtonProps {
    text : string,
    onButtonClicked? : Function,
    kind? : ButtonType,
    top? : number,
    bottom? : number,
    style?: CSSProperties
    children?: React.ReactNode,
}


const Button = ({kind = ButtonType.positiv, text, top=0, bottom=0, style, onButtonClicked, children} : ButtonProps) => {
    switch(kind) {
        case ButtonType.positiv: {
            return (
                <div className="button-container" style={{ marginTop:top, marginBottom:bottom, ...style}} onClick={() => { if(onButtonClicked) onButtonClicked()}}>
                    <button className="button-positiv">
                        <Text weight={TextWeight.semibold} color={Colors.white}>
                            {text}
                        </Text>
                        {children}    
                    </button>
                </div>
            );
        }
        case ButtonType.neutral: {
            return (
                <div className="button-container" style={{ marginTop:top, marginBottom:bottom, ...style}} onClick={() => { if(onButtonClicked) onButtonClicked()}}>
                    <button className="button-neutral">
                        <Text weight={TextWeight.semibold} color={Colors.oceanBlue}>
                            {text}
                        </Text>
                        {children}
                        </button>
                </div>
            );
        }
        case ButtonType.deactivated: {
            return (
                <div className="button-container" style={{ marginTop:top, marginBottom:bottom, ...style}} onClick={() => { if(onButtonClicked) onButtonClicked()}}>
                    <button className="button-deactiviated">
                        <Text weight={TextWeight.semibold} color={Colors.white}>
                            {text}
                        </Text>
                        {children}    
                    </button>
                </div>
            );
        }
        case ButtonType.upload: {
            return(
                <div className="button-container" style={{ marginTop:top, marginBottom:bottom, ...style}}>
                    <label className="button-neutral">
                        <Text weight={TextWeight.semibold} color={Colors.oceanBlue}>
                            {text}
                        </Text>
                    <input type="file" style={{display: "none"}} onChange={(e) => { if(onButtonClicked) onButtonClicked(e.target.files)}} accept="image/x-png,image/gif,image/jpeg"></input>
                    </label>
                </div>
            )
        }
    }




}

export default Button;