import React, { useEffect, useState, CSSProperties } from 'react';
import './Header.css'
import InfoModal from './InfoModal';
import { useHistory } from 'react-router-dom';
import Text, { TextSize } from './Text';
import Colors from '../../Colors';
import { isPlatform } from '@ionic/react';

enum BackNavigationColorScheme {
  light,
  dark
}

interface BackNavigationProps {
  compact?: boolean
  colorScheme?: BackNavigationColorScheme
  label?: string
}

const BackNavigation = ({compact = true, colorScheme = BackNavigationColorScheme.light, label}: BackNavigationProps) => {
  const history = useHistory();

  function handleBackClick() {
    history.goBack()
  }
  return (
    <>
      {
        compact === true ? (
          <div className="back-button" onClick={handleBackClick}>
            <img className="" src="/assets/images/BackButtonWhite.svg" alt="Back Button" />
          </div>
        ) : (
          <div className="back-button-extended" onClick={handleBackClick}>
            <img className="arrow" src="/assets/images/BackButtonBlue.svg" alt="Back Button" />
            <div className="label">
              <Text color={Colors.oceanBlue} size={TextSize.m}>{label}</Text>
            </div>
          </div>
        )
      }
    </>
  )
}

interface BrandedSectionProps {
  compactMode?: boolean
  enableBackNavigation?: boolean
}

const windowWith = () => {
  if (isPlatform("ios") || isPlatform("android")) {
    return window.innerWidth
  } else {
    return 580
  }
}

const BrandedSection = ({enableBackNavigation, compactMode}: BrandedSectionProps) => {
  const [windowWidth, setWindowWidth] = useState(windowWith)
  useEffect(() => {
    function handleResize() {
      setWindowWidth(windowWith)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  const height = windowWidth / 2.8
  const style: CSSProperties = { height }
  if (compactMode) {
      style["marginTop"] = -height + 50;
  }

  return (
    <div className="header" style={style}>
      {
        enableBackNavigation === true ?

          <BackNavigation />
        :
          <></>
      }
      {
        compactMode === false ? <img className="logoImage" src="/assets/images/CetaphilLogo@3x.png" alt="Cetaphil Logo" /> : <></>
      }
    </div>
  )
}

const InfoButton = () => {
  const [isModalPresented, setIsModalPresented] = useState(false);

  function alterModal() {
    setIsModalPresented(!isModalPresented)
  }

  return (
    <>
      <img src="/assets/images/InfoButton.svg" alt="" className="info-button" onClick={alterModal}/>
      <InfoModal showModal={isModalPresented} onCloseClick={alterModal} />
    </>
  )
}

interface ContentSectionProps extends BrandedSectionProps {
  showInfoButton?: boolean
  imageUrl?: string
  content?: React.ReactNode
  label?: string
}

const ContentSection = ({imageUrl, showInfoButton, content, enableBackNavigation = false, compactMode = false, label = ""}: ContentSectionProps) => {
  if (imageUrl) {
    return (
      <div className="content-image-main-container">
        <div className="content-image-container">
          <img src={imageUrl} alt="" />
          {
            showInfoButton === true ?
              (
                <div className="info-button-container">
                  <InfoButton />
                </div> )
            : <></>
          }
          <div className="content-image-mask">
            <img src="/assets/images/HeaderImageMask.svg" alt="" />
          </div>
        </div>
      </div>
    )
  }

  if (content) {
    return (
      <div className="header-content-container">
        <div className="header-content-navigation">
        {
          enableBackNavigation === true && compactMode === true ? (
            <BackNavigation compact={false} label={label} />
          ) : (
            <div className="label-without-back-button">
              <Text color={Colors.oceanBlue} size={TextSize.m}>{label}</Text>
            </div>
          )
        }
        </div>
        <div className="header-content">
        {content}
        </div>
        <div className="header-content-image-mask">
          <img src="/assets/images/HeaderImageMask.svg" alt="" />
        </div>
      </div>
    );
  }

  return (<></>);
}

interface HeaderProps extends ContentSectionProps {
  children?: React.ReactNode
}

const Header = ({imageUrl, showInfoButton = false, children, enableBackNavigation = false, compactMode = false, label = "" }: HeaderProps) => {
  return (
    <div className="header-container">
      <div className="brand-container">
        <BrandedSection enableBackNavigation={enableBackNavigation} compactMode={compactMode} />
      </div>
      <ContentSection imageUrl={imageUrl} content={children} showInfoButton={showInfoButton} enableBackNavigation={enableBackNavigation} compactMode={compactMode} label={label} />
    </div>
  );
}

export default Header;