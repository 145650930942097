import React, { useState, useContext } from "react"
import "./Profile.css"
import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  IonInput,
  IonLoading,
  IonToast,
} from "@ionic/react"
import Header from "../components/shared/Header"
import { User } from "../models/models"
import { GraphQLError } from "graphql"
import client from "../api/client"
import { GET_CURRENT_USER } from "../api/queries"
import SegmentedText from "../components/shared/SegmentedText"
import Text, { TextWeight, TextSize } from "../components/shared/Text"
import Colors from "../Colors"
import Button, { ButtonType } from "../components/shared/Button"
import { useMutation } from "@apollo/react-hooks"
import { UPDATE_PROFILE_MUTATION } from "../api/mutations"
import { UserContext } from "../contexts/UserContext"

interface ProfileState {
  user?: User
  errors?: ReadonlyArray<GraphQLError>
  loading: boolean
}

const Profile = () => {
  const userContext = useContext(UserContext)
  const [state, setState] = useState<ProfileState>({
    loading: true,
    errors: undefined,
    user: undefined,
  })
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState("")
  const [address, setAddress] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [city, setCity] = useState("")
  const [showLoading, setShowLoading] = useState(false)
  const [status, setStatus] = useState(<></>)
  const [mutate] = useMutation(UPDATE_PROFILE_MUTATION)
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false)

  async function loadUserData() {
    try {
      const { loading, errors, data } = await client.query({
        query: GET_CURRENT_USER,
        fetchPolicy: "network-only",
      })

      let user: User | undefined = undefined
      if (data) {
        user = data?.currentUser != null ? data?.currentUser : undefined
      }
      if (user !== undefined) {
        setFirstName(user?.firstName)
        setLastName(user?.lastName)
        setEmail(user?.email)
      }
      if (
        user?.deliveryAddress !== undefined &&
        user?.deliveryAddress !== null
      ) {
        setName(user?.deliveryAddress.name)
        setAdditionalInfo(user.deliveryAddress.additionalInfo)
        setAddress(user.deliveryAddress.address)
        setZipcode(user.deliveryAddress.zipcode)
        setCity(user.deliveryAddress.city)
      }
      setState({ user, errors, loading })
    } catch {
      setState({ ...state, errors: [new GraphQLError("Error while Loading")] })
    }
  }

  const onStatus = (message: string, duration = 2000, color = "danger") => {
    setStatus(
      <IonToast
        animated={true}
        duration={duration}
        message={message}
        isOpen={true}
        color={color}
      />
    )
    // make sure that we can reuse our toast
    setTimeout(() => {
      setStatus(<></>)
    }, duration)
  }

  const checkSubmitButton = () => {
    if (
      firstName !== state.user?.firstName ||
      lastName !== state.user?.lastName ||
      email !== state.user?.email
    ) {
      setIsReadyToSubmit(true)
    } else {
      setIsReadyToSubmit(false)
    }
  }

  const checkCompleteDeliveryAdress = () => {
    if (
      (name.length > 0 &&
        address.length > 0 &&
        zipcode.length > 0 &&
        city.length > 0) ||
      (name.length === 0 &&
        address.length === 0 &&
        zipcode.length === 0 &&
        city.length === 0)
    ) {
      return true
    }
    return false
  }

  const checkSubmitButtonDelivery = () => {
    if (
      checkCompleteDeliveryAdress &&
      (name !== state.user?.deliveryAddress?.name ||
        address !== state.user?.deliveryAddress?.address ||
        additionalInfo !== state.user?.deliveryAddress?.additionalInfo ||
        zipcode !== state.user?.deliveryAddress?.zipcode ||
        city !== state.user?.deliveryAddress?.city)
    ) {
      setIsReadyToSubmit(true)
    }
  }

  const logout = () => {
    userContext.logout()
  }

  function validMail() {
    console.log("Email: ", email)
    if (email.includes(".") && email.includes("@")) {
      return true
    }
    onStatus(
      "Bitte Prüfe deine E-Mail-Adresse. Sie entspricht nicht dem vorgegebenen Format."
    )
    return false
  }

  const onSubmit = async () => {
    if (checkCompleteDeliveryAdress()) {
      if (isReadyToSubmit) {
        if (validMail()) {
          try {
            setShowLoading(true)
            await mutate({
              variables: {
                firstName: firstName !== null ? firstName : "",
                lastName: lastName !== null ? lastName : "",
                email: email !== null ? email : "",
                deliveryAddress: {
                  name: name !== null ? name : "",
                  additionalInfo: additionalInfo !== null ? additionalInfo : "",
                  address: address !== null ? address : "",
                  zipcode: zipcode !== null ? zipcode : "",
                  city: city !== null ? city : "",
                },
              },
            })
            setShowLoading(false)
            setIsReadyToSubmit(false)
            onStatus(
              "Deine Daten wurden erfolgreich aktualisiert.",
              2000,
              "success"
            )
          } catch (error) {
            console.log("error: " + error.message)
            setShowLoading(false)
            onStatus(
              "Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.",
              3000
            )
          }
        }
      } else {
        onStatus("Es wurden keine Änderungen vorgenommen.", 2000, "warning")
      }
    } else {
      onStatus(
        "Bitte gebe deine Lieferadresse vollständig an oder lasse alle Felder frei, damit deine Prämien in die Apotheke geliefert werden.",
        3500,
        "warning"
      )
    }
  }

  useIonViewDidEnter(() => {
    loadUserData()
  })

  return (
    <IonPage>
      <IonContent>
        <Header
          key="profile"
          showInfoButton={false}
          enableBackNavigation={false}
          compactMode={false}
        >
          <div className="profile-header-title">
            <Text
              weight={TextWeight.light}
              color={Colors.brownishGreyTwo}
              size={TextSize.xl}
            >
              Dein Profil
            </Text>
          </div>
          <div className="profile-header-content-container">
            <div className="profile-header-content-pharmacy">
              <Text
                color={Colors.oceanBlue}
                size={TextSize.s}
                weight={TextWeight.semibold}
                style={{ textTransform: "uppercase", marginBottom: 13 }}
              >
                Apotheke:
              </Text>
              <Text color={Colors.oceanBlue} weight={TextWeight.semibold}>
                {state.user?.pharmacy.name}
              </Text>
              <Text color={Colors.oceanBlue} weight={TextWeight.semibold}>
                {state.user?.pharmacy.address}
              </Text>
              <Text color={Colors.oceanBlue} weight={TextWeight.semibold}>
                {state.user?.pharmacy.zipcode} {state.user?.pharmacy.city}
              </Text>
            </div>

            <div className="profile-header-content-credits-container">
              <div className="profile-header-line"></div>
              <div className="profile-header-content-credits">
                <Text
                  color={Colors.oceanBlue}
                  size={TextSize.s}
                  weight={TextWeight.semibold}
                  style={{ textTransform: "uppercase", marginBottom: 5 }}
                >
                  Punkte:
                </Text>
                <Text
                  color={Colors.oceanBlue}
                  size={TextSize.xxl}
                  weight={TextWeight.semibold}
                >
                  {state.user?.credits}
                </Text>
              </div>
            </div>
          </div>
        </Header>
        <div className="profile-container">
          <div className="profile-subline">
            <Text weight={TextWeight.semibold}>
              Hier kannst du deine persönlichen Daten ergänzen und ändern:
            </Text>
          </div>
          <SegmentedText isBottomLine={false}>
            <div className="profile-segmented-container">
              <Text
                weight={TextWeight.semibold}
                size={TextSize.s}
                color={Colors.oceanBlue}
                style={{ textTransform: "uppercase", marginBottom: 8 }}
              >
                Vorname:
              </Text>
              <IonInput
                placeholder="Vorname"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    firstName?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={firstName}
                onIonBlur={(e) => {
                  checkSubmitButton()
                }}
                onIonChange={(e) => {
                  if (e !== null && e.detail.value !== null) {
                    console.log("e: ", e)
                    setFirstName(e.detail.value!.trim())
                  }
                }}
              ></IonInput>
            </div>
          </SegmentedText>
          <SegmentedText isBottomLine={false}>
            <div className="profile-segmented-container">
              <Text
                weight={TextWeight.semibold}
                size={TextSize.s}
                color={Colors.oceanBlue}
                style={{ textTransform: "uppercase", marginBottom: 8 }}
              >
                Nachname:
              </Text>
              <IonInput
                placeholder="Nachname"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    lastName?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={lastName}
                onIonBlur={(e) => {
                  checkSubmitButton()
                }}
                onIonChange={(e) => {
                  if (e !== null && e.detail.value !== null) {
                    setLastName(e.detail.value!.trim())
                  }
                }}
              ></IonInput>
            </div>
          </SegmentedText>
          <SegmentedText>
            <div className="profile-segmented-container">
              <Text
                weight={TextWeight.semibold}
                size={TextSize.s}
                color={Colors.oceanBlue}
                style={{ textTransform: "uppercase", marginBottom: 8 }}
              >
                E-Mail-Adresse:
              </Text>
              <IonInput
                placeholder="Vorname"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    email?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                inputMode="email"
                value={email}
                onIonBlur={(e) => {
                  checkSubmitButton()
                }}
                onIonChange={(e) => {
                  setEmail(e.detail.value!.trim())
                }}
              ></IonInput>
            </div>
          </SegmentedText>
          <SegmentedText>
            <div className="profile-segmented-container">
              <Text
                weight={TextWeight.semibold}
                size={TextSize.s}
                color={Colors.oceanBlue}
                style={{ textTransform: "uppercase", marginBottom: 8 }}
              >
                Lieferadresse für Prämien:
              </Text>

              <IonInput
                placeholder="Name"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    name?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={name}
                onIonBlur={(e) => {
                  checkSubmitButtonDelivery()
                }}
                onIonChange={(e) => {
                  setName(e.detail.value!.trim())
                }}
              ></IonInput>

              <IonInput
                placeholder="Zusatz"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    additionalInfo?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={additionalInfo}
                onIonBlur={(e) => {
                  checkSubmitButtonDelivery()
                }}
                onIonChange={(e) => {
                  setAdditionalInfo(e.detail.value!.trim())
                }}
              ></IonInput>

              <IonInput
                placeholder="Straße & Hausnr."
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    address?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={address}
                onIonBlur={(e) => {
                  checkSubmitButtonDelivery()
                }}
                onIonChange={(e) => {
                  setAddress(e.detail.value!.trim())
                }}
              ></IonInput>

              <IonInput
                placeholder="PLZ"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    zipcode?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                }}
                value={zipcode}
                onIonBlur={(e) => {
                  checkSubmitButtonDelivery()
                }}
                onIonChange={(e) => {
                  setZipcode(e.detail.value!.trim())
                }}
              ></IonInput>

              <IonInput
                placeholder="Ort"
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: TextSize.m,
                  color:
                    city?.length > 0
                      ? Colors.brownishGrey
                      : Colors.very_light_pink,
                  fontFamily: "OpenSans",
                  paddingStart: 10,
                }}
                value={city}
                onIonBlur={(e) => {
                  checkSubmitButtonDelivery()
                }}
                onIonChange={(e) => {
                  setCity(e.detail.value!.trim())
                }}
              ></IonInput>
            </div>
          </SegmentedText>

          <div className="profile-save-button">
            <Button
              kind={
                isReadyToSubmit ? ButtonType.positiv : ButtonType.deactivated
              }
              text="Änderungen speichern"
              onButtonClicked={onSubmit}
            ></Button>
          </div>

          <div className="profile-save-button">
            <Button
              kind={ButtonType.positiv}
              text="Logout"
              onButtonClicked={logout}
            ></Button>
          </div>
        </div>
        {status}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Daten werden übertragen..."}
        />
      </IonContent>
    </IonPage>
  )
}

export default Profile
