import React, { useState, useEffect } from 'react'
import { Campaign } from '../../models/models'
import { GraphQLError } from 'graphql'
import { RouteComponentProps } from 'react-router'
import { IonPage, IonContent, IonInput, IonLoading, IonToast } from '@ionic/react'
import Header from '../../components/shared/Header'
import "./CampaignTrainingProof.css"
import Text, { TextSize, TextWeight } from '../../components/shared/Text'
import SegmentedText from '../../components/shared/SegmentedText'
import Button, { ButtonType } from '../../components/shared/Button'
import client from '../../api/client'
import { GET_CAMPAIGN } from '../../api/queries'
import Colors from '../../Colors'
import { PROOF_OF_TRAINING_MUTATION } from '../../api/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

interface CampaignTrainingProofRouterProps {
    id: string;
  }

interface CampaignTrainingProofProps extends RouteComponentProps<CampaignTrainingProofRouterProps> {
    location: any
  }

interface CampaignTrainingProofState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
}


const CampaignTrainingProof = ({match, location} : CampaignTrainingProofProps) => {
    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }
    const [state, setState] = useState<CampaignTrainingProofState>({loading: true, errors: undefined, campaign: undefined})

    const [trainingCode, setTrainingCode] = useState("")
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false)
    const [mutate] = useMutation(PROOF_OF_TRAINING_MUTATION)
    const [error, setError] = useState(<></>)
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();

    async function loadCampaignDetails() {
        try {
            const { loading, errors, data } = await client.query({
                query: GET_CAMPAIGN,
                variables: { id }
            })

            let campaign: (Campaign|undefined) = undefined
            if (data) {
                campaign = data.campaigns.length > 0 ? data.campaigns[0] : undefined;
            }

            setState({ campaign, errors, loading })
        } catch {
            setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }

      }

    const startLoading = () => {
        loadCampaignDetails()
    }

    useEffect(startLoading, [id])

      const onError = (message: string, duration = 1000) => {
        setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
            setError(<></>)
        }, duration)
    }

      const checkSubmitButton = () => {
        if(trainingCode.length === 4 || trainingCode.length === 5) {
            setIsReadyToSubmit(true)
        } else {
            setIsReadyToSubmit(false)
        }
      }

      useEffect(checkSubmitButton, [trainingCode])

      const onSubmit = async () => {
        if (isReadyToSubmit === false) {
            return
        }
        
        const campainId = state.campaign?.id;
        try {
            setShowLoading(true)
            await mutate({
                variables: {
                    campaignId: campainId,
                    code: trainingCode
                }
            })
            setShowLoading(false)
            setTrainingCode("")
            history.replace(`/campaign-proof-done/${campainId}`, { title })
        } catch (error) {
            setShowLoading(false)
            onError("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
        }
    }

    return (

        <IonPage>
            <IonContent>
                <Header
                        key="campaign-training-proof"
                        showInfoButton={false}
                        enableBackNavigation={true}
                        compactMode={true}
                        label={title}>
                            <></>
                    </Header>

                    <div className="campaign-training-proof-container">
                        <div className="campaign-training-proof-title">
                            <Text color={Colors.oceanBlue} size={TextSize.xl} weight={TextWeight.light}>Nachweis einreichen</Text>
                        </div>

                        <SegmentedText top={30} bottom={50}>
                            <div className="campaign-training-proof-segmented-text">
                                <Text style={{marginRight:5}}>Für:</Text>
                                <div className="campaign-training-proof-segmented-title">
                                <Text>{state.campaign?.title}</Text>
                                <Text style={{marginLeft:5}}>{state.campaign?.subtitle}</Text>
                                </div>
                            </div>
                        </SegmentedText>

                        <div className="campaign-training-proof-text">
                            <Text color={Colors.oceanBlue} weight={TextWeight.semibold} size={TextSize.s}>CODE:</Text>
                        </div>
                        <div className="campaign-training-proof-text">
                            <Text weight={TextWeight.semibold}>Bitte gib hier den Code ein, den Du am Ende der Schulung erhalten hast.</Text>
                        </div>

                        <div className="code-input">
                            <IonInput
                                style={{textAlign:"center", fontWeight:TextWeight.semibold, color:Colors.oceanBlue, fontSize:TextSize.xl}}
                                color={Colors.oceanBlue}
                                type="text"
                                maxlength={5}
                                required={true}
                                value = {trainingCode}
                                onIonChange={e => setTrainingCode(e.detail.value!)} />
                        </div>

                        <div className="campaign-training-proof-submit-button">
                            <Button kind={isReadyToSubmit ? ButtonType.positiv : ButtonType.deactivated} text="Teilnahme bestätigen" onButtonClicked={onSubmit}></Button>
                        </div>
                    </div>
                    { error }
                    <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
            </IonContent>
        </IonPage>
    )
}

export default CampaignTrainingProof