import React, { useState } from 'react'
import Text, { TextSize, TextWeight } from './Text'
import './PlacementSelectionTile.css'
import Colors from '../../Colors'

interface PlacementSelectionTileProps {
    imgUrl? : string
    onPlacementCountChanged : Function
}

const PlacementSelectionTile = ({imgUrl, onPlacementCountChanged} : PlacementSelectionTileProps) => {

    const [placementCount, setPlacementCount] = useState(1)

    const onProductCountChangedUp = () => {
        const newCount = placementCount + 1
        setPlacementCount(newCount)
        onPlacementCountChanged(newCount)
    }

    const onProductCountChangedDown = () => {
        if(placementCount > 1) {
            const newCount = placementCount - 1
            setPlacementCount(newCount)
            onPlacementCountChanged(newCount)
        }
    }

    return (
        <div className="placement-selection-tile-container">
            <div className="placement-selection-tile-image-container">
                <img className="placement-selection-tile-image" src={imgUrl} alt="" />
            </div>

            <div className="placement-selection-tile-text-container">
                    <Text size={TextSize.s} weight={TextWeight.semibold}>Regalboden mit 80cm Länge</Text>
                    <Text color={Colors.brownGrey} size={TextSize.s}> Gebe hier die Anzahl ein.</Text>
            </div>
            <div className="placement-selection-tile-counting-container">
                <div className="placement-selection-tile-line"></div>
                <div className="placement-selection-tile-counter-container">
                    <div onClick={onProductCountChangedUp}>
                        <img className="placement-selection-tile-counter-arrow-up" src="/assets/images/CounterArrowUp.svg" alt="Counter Up" />
                    </div>
                    <div className="placement-selection-tile-counter-number">
                        <Text color={Colors.oceanBlue} size={TextSize.xl}>{placementCount}</Text>
                    </div>
                    <div onClick={onProductCountChangedDown}>
                        <img className="placement-selection-tile-counter-arrow-down" src="/assets/images/CounterArrowDown.svg" alt="Counter Down" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PlacementSelectionTile