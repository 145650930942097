import React, { useState, useEffect } from 'react'
import './CampaignPlacementProof.css'
import { IonPage, IonContent, IonToast, IonLoading } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Campaign } from '../../models/models'
import { GraphQLError } from 'graphql'
import Header from '../../components/shared/Header'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'
import SegmentedText from '../../components/shared/SegmentedText'
import { GET_CAMPAIGN } from '../../api/queries'
import client from '../../api/client'
import DateRangeSelect from '../../components/shared/DateRangeSelect'
import ProductImagePicker from '../../components/shared/ProductImagePicker'
import ImageThumbnail from '../../components/shared/ImageThumbnail'
import Button, { ButtonType } from '../../components/shared/Button'
import PlacementSelectionTile from '../../components/shared/PlacementSelectionTile'
import Colors from '../../Colors'
import { PROOF_OF_PLACMENT_MUTATION } from '../../api/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'


interface CampaignPlacementProofRouterProps {
    id: string;
  }

interface CampaignPlacementProps extends RouteComponentProps<CampaignPlacementProofRouterProps> {
    location: any
  }

  interface CampaignPlacementProofState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
  }



const CampaignPlacementProof = ({match, location} : CampaignPlacementProps) => {

    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }
    const [state, setState] = useState<CampaignPlacementProofState>({loading: true, errors: undefined, campaign: undefined})
    const [fromDate, setFromDate] = useState("2020-02-21")
    const [toDate, setToDate] = useState("2020-02-21")
    const [selectedImages, setImages] = useState<(string[])>([])
    const [error, setError] = useState(<></>)
    const [placementCount, setPlacementCount] = useState(1)
    const [mutate] = useMutation(PROOF_OF_PLACMENT_MUTATION)
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();

    const onChangedFromDate = (value : string) => {
        setFromDate(value)
    }

    const onChangedToDate = (value : string) => {
        setToDate(value)
    }

    const onImageChosen = (imageData: string) => setImages([...selectedImages, imageData])

    const onImageDelete = (index: number) => {
        const images = Object.assign([], selectedImages);
        images.splice(index, 1)
        setImages(images)
    }

    const onPlacementCountChanged = (count : number) => {
        setPlacementCount(count)
    }

    const onError = (message: string, duration = 1000) => {
        setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
            setError(<></>)
        }, duration)
    }

    const onSubmit = async () => {

        const campainId = state.campaign?.id;
        try {
            setShowLoading(true)
            await mutate({
                variables: {
                    campaignId: campainId,
                    files: selectedImages,
                    fromDate: fromDate,
                    tillDate: toDate,
                    numberOfShelf: placementCount
                }
            })
            let credits = placementCount * (state.campaign?.credits ?  state.campaign?.credits : 0)
            setShowLoading(false)
            history.replace(`/campaign-proof-done/${campainId}`, { title, credits})
        } catch (error) {
            console.log("Ausgabe: Error : " + error)
            setShowLoading(false)
            onError("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
        }
    }

    async function loadCampaignDetails() {
        const { loading, errors, data } = await client.query({
          query: GET_CAMPAIGN,
          variables: { id }
        })

        let campaign: (Campaign|undefined) = undefined
        if (data) {
          campaign = data.campaigns.length > 0 ? data.campaigns[0] : undefined;
        }

        if(campaign?.startsAt !== undefined) {
            setFromDate(campaign?.startsAt)
        }

        if(campaign?.expiresAt !== undefined) {
            setToDate(campaign?.expiresAt)
        }
        setError(<></>)
        setState({campaign, errors, loading })
      }

    const startLoading = () => {
        loadCampaignDetails()
    }

    useEffect(startLoading, [id])


    return (

        <IonPage>
            <IonContent>
              <Header
                key="campaign-placement-proof"
                showInfoButton={false}
                enableBackNavigation={true}
                compactMode={true}
                label={title}>
                    <></>
                </Header>
                <div className="campaign-placement-proof-container">

                   <div className="campaign-placement-proof-title">
                        <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl} style={{letterSpacing:0}}>
                            Nachweis einreichen
                        </Text>
                    </div>
                    
                    <SegmentedText>
                        <div className="campaign-placement-proof-segmend">
                            <Text style={{marginRight:5}}>
                                Für:
                            </Text>
                            <div className="campaign-placement-proof-segmend-title">
                            <Text >
                                {state.campaign?.title}
                            </Text>
                            <Text style={{marginLeft:5}}>
                                {state.campaign?.subtitle}
                            </Text>
                            </div>
                        </div>
                    </SegmentedText>
                    <div className="campaign-placement-proof-steps">
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            1. Zeitraum für Nachweis angeben:
                        </Text>
                    </div>

                    <DateRangeSelect fromDate={fromDate} toDate={toDate} onChangedFromDate={onChangedFromDate} onChangedToDate={onChangedToDate}></DateRangeSelect>
                    <div className="campaign-placement-proof-steps">
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            2. Anzahl der Regalböden:
                        </Text>
                    </div>

                    <PlacementSelectionTile imgUrl={state.campaign?.teaserImage} onPlacementCountChanged={onPlacementCountChanged}/>

                    <div className="campaign-placement-proof-steps">
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            3. Fotonachweis
                        </Text>
                    </div>

                    <div>
                        {
                            selectedImages.map((image, index) => (
                                <ImageThumbnail key={index} imageData={image} index={index} onDeleteClicked={onImageDelete} />
                            ))
                        }
                        {
                        (selectedImages.length > 0) ? (
                            <div className="campaign-sale-more-products-text">
                                <Text weight={TextWeight.semibold} color={Colors.brownGrey}>Weiteres Foto hinzufügen:</Text>
                            </div>
                        ) : (<></>)
                        }
                    </div>

                    <div className="campaign-placement-proof-image-selection">
                        <ProductImagePicker onImageChosen={onImageChosen} />
                    </div>

                    <div className="campaign-placement-proof-submit-button-container">
                        <Button kind={ButtonType.positiv} text="Nachweis senden" onButtonClicked={onSubmit}></Button>
                    </div>
                </div>
                    { error }
                    <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
            </IonContent>
        </IonPage>

    )

}

export default CampaignPlacementProof