import React, { useState } from 'react'
import Header from '../components/shared/Header'
import Text, { TextWeight, TextSize } from '../components/shared/Text'
import Colors from '../Colors'

import './Registration.css';
import { IonInput, IonToast, IonLoading, IonPage, IonContent } from '@ionic/react';
import Button from '../components/shared/Button';
import validator from 'validator';
import { useMutation } from '@apollo/react-hooks';
import { REGISTER_USER_MUTATION } from '../api/mutations';

interface RegistrationProps {
  onRegistrationFinished: Function
  onBackToLogin: Function
}

const Registration = ({ onRegistrationFinished, onBackToLogin }: RegistrationProps) => {
  const [eMail, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [pharmacyCode, setPharmacyCode] = useState('')
  const [error, setError] = useState(<></>)
  const [showLoading, setShowLoading] = useState(false)

  const errorRegistration = (res : Error) => {
    setShowLoading(false)
    onError(res.message.replace("GraphQL error: ",""), 2000)
 }

 const succuessRegistration = (res : any) => {
  setShowLoading(false)
  onRegistrationFinished()
 }

  const [registerMutation] = useMutation(REGISTER_USER_MUTATION, {onCompleted: succuessRegistration, onError:errorRegistration})

  function onError(message: string, duration = 1000) {
    setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
    // make sure that we can reuse our toast
    setTimeout(() => {
        setError(<></>)
    }, duration)
  }

  async function onSubmitRegistration() {
    if (!validator.isEmail(eMail)) {
      onError("Die eingebene E-Mail ist nicht gültig.")
      return
    }

    if (password !== passwordConfirmation) {
      onError("Das eingebene Passwort stimmt nicht mit der Wiederholung überein.")
      return
    }

    if (validator.isEmpty(pharmacyCode)) {
      onError("Bitte geben Sie den Code ihrer Apotheke ein.")
      return
    }

    setShowLoading(true)

    try {
      await registerMutation({
        variables: {
          email: eMail,
          password,
          registrationCode: pharmacyCode
        }
      })
    } catch {
      onError("Bei der Registrierung ist ein Fehler aufgetreten.")
      setShowLoading(false)
    }
  }

  return (
    <IonPage>
      <IonContent>
      <Header key="registration" />
      <div className="registration-container">

          <Text weight={TextWeight.light} size={TextSize.xl} color={Colors.oceanBlue} style={{marginTop:20, textAlign: "center"}}>
              Registrierung
          </Text>
          <div className="registration-user-info-container">
            <div className="registration-text-input">
              <IonInput
                  style={{
                    textAlign:"left",
                    fontWeight: "600",
                    fontSize: TextSize.m,
                    color: Colors.brownGrey,
                    fontFamily: "OpenSans"
                  }}
                  color={Colors.oceanBlue}
                  type="email"
                  required={true}
                  value={eMail}
                  placeholder="E-Mail"
                  onIonChange={(e) => setEmail(e.detail.value!)} />
            </div>
            <div className="registration-text-input">
              <IonInput
                  style={{
                    textAlign:"left",
                    fontWeight: "600",
                    fontSize: TextSize.m,
                    color: Colors.brownGrey,
                    fontFamily: "OpenSans",
                    backgroundColor: "white",
                    padding: 4
                  }}
                  color={Colors.oceanBlue}
                  type="password"
                  required={true}
                  value={password}
                  placeholder="Passwort erstellen"
                  onIonChange={(e) => setPassword(e.detail.value!)} />
            </div>
            <div className="registration-text-input">
              <IonInput
                  style={{
                    textAlign:"left",
                    fontWeight: "600",
                    fontSize: TextSize.m,
                    color: Colors.brownGrey,
                    fontFamily: "OpenSans"
                  }}
                  color={Colors.oceanBlue}
                  type="password"
                  required={true}
                  value={passwordConfirmation}
                  placeholder="Passwort wiederholen"
                  onIonChange={(e) => setPasswordConfirmation(e.detail.value!)} />
            </div>
          </div>

        <div className="registration-pharmacy-info-container">
          <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textAlign: "center", textTransform: "uppercase"}}>
              Apotheken-Code
          </Text>
          <div className="registration-seperation-line"/>
          <Text size={TextSize.s} color={Colors.brownishGreyTwo} style={{display: "block", textAlign: "center", marginTop:10, marginBottom:20}}>
            Gib hier bitte die sechsstellige Kennziffer
            ein, die alle teilnehmenden Apotheken
            zur Weitergabe ans Team erhalten.
          </Text>

          <div className="registration-text-input">
            <IonInput
                style={{
                  textAlign:"left",
                  fontWeight: "600",
                  fontSize: TextSize.m,
                  color: Colors.brownGrey,
                  fontFamily: "OpenSans",
                  backgroundColor: "white",
                  // padding: 4
                }}
                color={Colors.oceanBlue}
                type="text"
                required={true}
                value={pharmacyCode}
                placeholder="Apotheken-Code"
                onIonChange={(e) => setPharmacyCode(e.detail.value!)} />
          </div>
        </div>

        <div className="registration-submit-container">
          <Button text="Registrieren" onButtonClicked={onSubmitRegistration} />
          <Text size={TextSize.s} color={Colors.brownishGreyTwo} style={{display: "block", textAlign: "center", marginTop:10, marginBottom:20}}>
            Mit der Registrierung erkennen Sie unsere aktuellen <a href="https://www.cetaphil.de/datenschutzerklaerung" target="">Datenschutzbestimmungen</a> an.
          </Text>
          <div className="registration-back-action-container" onClick={ () => {onBackToLogin()}}>
            <Text weight={TextWeight.normal} size={TextSize.s} color={Colors.oceanBlue}>
              Zurück zum Login
            </Text>
          </div>
        </div>
        { error }
        <IonLoading
            isOpen={showLoading}
            message={'Sie werden registriert...'}
          />
      </div>
      </IonContent>
    </IonPage>
  )
}

export default Registration