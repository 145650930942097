import { gql } from "apollo-boost";

/*
  Proof of Cooperation
*/
//#region PoC Mutations
export const PROOF_OF_COOPERATION_MUTATION = gql`
  mutation ProofSale(
    $campaignId: ID!
    $fromDate: String!
    $tillDate: String!
    $lineItems: [LineItemInput!]
    $files: [Upload!]!
  ) {
    proofSale(
      campaignId: $campaignId
      fromDate: $fromDate
      tillDate: $tillDate
      lineItems: $lineItems
      files: $files
    ) {
      proof {
        id
        credits
      }
    }
  }
`;

export const PROOF_OF_TRAINING_MUTATION = gql`
  mutation ProofTraining(
    $campaignId: ID!
    $code: String!
  ) {
    proofOnlineCourse(
      campaignId: $campaignId
      code: $code
    ) {
      proof {
        id
      }
    }
  }
`;

export const PROOF_OF_DISPLAY_MUTATION = gql`
  mutation ProofDisplay(
    $campaignId: ID!
    $fromDate: String!
    $tillDate: String!
    $files: [Upload!]!
  ) {
    proofDisplay(
      campaignId: $campaignId
      fromDate: $fromDate
      tillDate: $tillDate
      files: $files
    ) {
      proof {
        id
      }
    }
  }
`;

export const PROOF_OF_PLACMENT_MUTATION = gql`
  mutation ProofPlacement(
    $campaignId: ID!
    $fromDate: String!
    $tillDate: String!
    $files: [Upload!]!
    $numberOfShelf: Int!
  ) {
    proofPlacement(
      campaignId: $campaignId
      fromDate: $fromDate
      tillDate: $tillDate
      files: $files
      numberOfShelf: $numberOfShelf

    ) {
      proof {
        id
      }
    }
  }
`;
//#endregion PoC Mutations

/*
  Incentive
*/
//#region Incentive Mutations

export const SUBMIT_INCENTIVE_MUTATION = gql`
  mutation SubmitIncentive(
    $creditsTotal: Int!
    $incentives: [IncentiveInput!]
  ) {
    submitIncentive(
      creditsTotal: $creditsTotal
      incentives: $incentives
    ) {
      user {
        credits
      }
    }
  }
`;


//#endregion Incentive Mutations

/*
  Offer
*/
//#region Offer Mutations

export const REQUEST_OFFER_MUTATION = gql`
  mutation RequestOffer(
    $campaignId: ID!
  ) {
    requestOffer(
      campaignId: $campaignId
    ) {
      user {
        credits
      }
    }
  }
`;


//#endregion Offer Mutations

/*
  User
*/
//#region User Mutations
export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser(
    $registrationCode: String!
    $email: String!
    $password: String!
  ) {
    registerUser(
      registrationCode: $registrationCode
      email: $email
      password: $password
    ) {
      user {
        id
      }
    }
  }
`

export const SIGN_IN_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!) {
    signInUser(email: $email, password: $password) {
      token
    }
  }
`

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProdile(
    $firstName: String!
    $lastName: String!
    $email: String!
    $deliveryAddress: DeliveryAddressInput!
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      deliveryAddress : $deliveryAddress
    ) {
      user {
        id
      }
    }
  }
`
//#endregion User Mutations