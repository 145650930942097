
export interface CampaignCategoryData {
  campaignCategories: CampaignCategory[];
}

export interface CampaignCategory {
  id: number;
  title: string;
  categoryType: string;
  legalText: string;
  previewUrl: string;
  description: string;
  credits: number;
  campaigns: Campaign[];
  order: number;
}

export interface CampaignData {
  campaigns: Campaign[]
}

export interface Campaign {
  id: number;
  title: string;
  subtitle: string;
  expiresAt: string;
  startsAt: string;
  credits: number
  description: string;
  teaserImage: string;
  images: string[];
  trainingLink: string;
  order: number;
  isInhouseTraining: boolean;
  requestOffer: boolean;
  campaignCategory: CampaignCategory;
}

export interface ProductData {
  products: Product[]
}

export interface Product {
  id: string;
  pzn: string;
  productLine: ProductLine;
  name: string;
  priceCents: number;
  preview: string
  portion: string
}

export interface ProductLine {
  id: string;
  name: string;
}

export enum CategoryType {
  training = "training",
  sale = "sale",
  display = "display",
  supply = "supply",
  promotion = "promotion",
  placement = "placement"
}


export interface IncentiveCategoryData {
  incentiveCategories: IncentiveCategory[];
}

export interface IncentiveCategory {
  id: number;
  title: string;
  subtitle: string,
  image: string;
  incentives: Incentive[];
}

export interface Incentive {
  id: number;
  credits: number;
  title: string;
  product: Product
}

export interface User {
  credits: number;
  deliveryAddress : DeliveryAddress;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  pharmacy: Pharmacy
}

export interface DeliveryAddress {
  additionalInfo: string;
  address: string;
  city: string;
  name: string;
  zipcode: string;
}

export interface Pharmacy {
  address: string;
  city: string;
  id: number;
  name: string;
  zipcode: string;
}

export interface Message {
  content: string;
  publishedAt: string;
  id: number;
  title: string;
  teaserImage: string
}