import React from 'react'
import './CampaignTile.css'
import { Campaign } from '../../models/models'
import Text, { TextWeight, TextSize } from './Text';

interface CampaignTileProps {
  campaign: Campaign;
}

const CampaignTile: React.FC<CampaignTileProps> = ({ campaign }) => (
  <div className="campaign-tile-container">
    <img className="teaser-image" src={campaign.teaserImage} alt="" />
    <div className="campaign-title-container">
      <Text size={TextSize.s}>{campaign.title}</Text>    
      <Text weight={TextWeight.semibold} style={{lineHeight: 1.5, letterSpacing: 'normal'}}>
        {campaign.subtitle}
      </Text>
    </div>
  </div>
);

export default CampaignTile;