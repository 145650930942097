import React from 'react'
import { Incentive } from '../../models/models'
import Text, { TextSize, TextWeight } from './Text'
import './IncentiveSelectionTile.css'
import Colors from '../../Colors'

interface IncentiveSelectionTileProps {
    incentive : Incentive
    incentiveCount? : number
    onIncentiveCountChanged : Function
}

const IncentiveSelectionTile = ({incentive, incentiveCount = 0, onIncentiveCountChanged} : IncentiveSelectionTileProps) => {

    const onIncentiveCountChangedUp = () => {
        const newCount = incentiveCount + 1
        onIncentiveCountChanged(incentive, newCount)
    }

    const onIncentiveCountChangedDown = () => {
        if(incentiveCount > 0) {
            const newCount = incentiveCount - 1
            onIncentiveCountChanged(incentive, newCount)
        }
    }

    return (
        <div className="incentive-selection-tile-container">
            <div className="incentive-selection-tile-image-container">
                <img className="incentive-selection-tile-image" src={incentive?.product.preview} alt="" />
                <Text size={TextSize.s}>{incentive.product.portion}</Text>
            </div>
            <div className="incentive-selection-tile-text-container">
                <div className="incentive-selection-tile-header-title">
                    <Text size={TextSize.xxs}>Cetaphil<sup>®</sup></Text>
                    <Text size={TextSize.s} weight={TextWeight.semibold}>{incentive?.product.name}</Text>
                </div>
                <div>
                    <Text color={Colors.oceanBlue} size={TextSize.s}> {incentive?.credits} Punkte | UVP {(incentive?.product.priceCents / 100 ).toFixed(2)} €</Text>
                </div>
            </div>
            <div className="incentive-selection-tile-counting-container">
                <div className="incentive-selection-tile-line"></div>
                <div className="incentive-selection-tile-counter-container">
                    <div onClick={onIncentiveCountChangedUp}>
                        <img className="incentive-selection-tile-counter-arrow-up" src="/assets/images/CounterArrowUp.svg" alt="Counter Up" />
                    </div>
                    <div className="incentive-selection-tile-counter-number">
                        <Text color={incentiveCount === 0? Colors.brownGrey : Colors.oceanBlue} size={TextSize.xl}>{incentiveCount}</Text>
                    </div>
                    <div onClick={onIncentiveCountChangedDown}>
                        <img className="incentive-selection-tile-counter-arrow-down" src={ incentiveCount === 0? "/assets/images/CounterArrowDownDisable.svg" : "/assets/images/CounterArrowDown.svg"} alt="Counter Down" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default IncentiveSelectionTile