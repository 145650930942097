import React from 'react'
import "./SegmentedText.css"

interface SegmentedTextProps {
    children?: React.ReactNode,
    top? : number,
    bottom? : number,
    isBottomLine? : boolean
    isTopLine? : boolean
}

const SegmentedText = ({top=0, bottom=0, children, isTopLine = true, isBottomLine=true} : SegmentedTextProps) => {

    return (
        <div className="segment-text-container"style={{ marginTop:top, marginBottom:bottom}}>
        {
            isTopLine ? 
                (<div className="segment-text-line"/>) :
                (<></>)
        }
            {children}
        {
            isBottomLine ? 
                (<div className="segment-text-line"/>) : 
                (<></>)
        }
        </div>
    );

}

export default SegmentedText