import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonSpinner, IonToast, IonLoading } from '@ionic/react'
import Header from '../../components/shared/Header'
import { RouteComponentProps } from 'react-router'
import { Campaign } from '../../models/models'
import { GraphQLError } from 'graphql'
import './CampaignTrainingDetails.css'
import Text, { TextSize, TextWeight } from '../../components/shared/Text'
import Colors from '../../Colors'
import Button, { ButtonType } from '../../components/shared/Button'
import SegmentedText from '../../components/shared/SegmentedText'
import { Link } from 'react-router-dom'
import client from '../../api/client'
import { GET_CAMPAIGN } from '../../api/queries'
import { useMutation } from '@apollo/react-hooks'
import { REQUEST_OFFER_MUTATION } from '../../api/mutations'

interface CampaignTrainingDetailsRouterProps {
    id: string;
  }

interface CampaignDisplaysDetailsProps extends RouteComponentProps<CampaignTrainingDetailsRouterProps> {
    location: any
  }

interface CampaignTrainingDetailsState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
}

const CampaignTrainingDetails = ({match, location} : CampaignDisplaysDetailsProps) => {

    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }
    const [mutate] = useMutation(REQUEST_OFFER_MUTATION)
    const [showLoading, setShowLoading] = useState(false);
    const [status, setStatus] = useState(<></>)
    const [state, setState] = useState<CampaignTrainingDetailsState>({loading: true, errors: undefined, campaign: undefined})

    async function loadCampaignDetails() {
        try {
            const { loading, errors, data } = await client.query({
            query: GET_CAMPAIGN,
            variables: { id }
            })

            let campaign: (Campaign|undefined) = undefined
            if (data) {
            campaign = data.campaigns.length > 0 ? data.campaigns[0] : undefined;
            }

            setState({ campaign, errors, loading })
        }  catch {
            setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }
    }

    const startLoading = () => {
        loadCampaignDetails()
    }

    const onStatus = (message: string, duration = 1000, color="danger") => {
        setStatus(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={color}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
          setStatus(<></>)
        }, duration)
      }

    const requestOfferButtonText = () => {
        if (state.campaign?.isInhouseTraining) { 
          return "Angebot"
        } else {
          return "Info"
        }
      }

       const requestOffer = async() => {
        const campainId = state.campaign?.id;

        try {
          setShowLoading(true)
          await mutate({
              variables: {
                  campaignId: campainId,
              }
          })
          setShowLoading(false)
          onStatus(requestOfferButtonText() + " angefordert", 2000, "success")
      } catch (error) {
        console.log("error: "+error)
          setShowLoading(false)
          onStatus("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
      }
      }

    useEffect(startLoading, [id])

    return (
        <IonPage>
            <IonContent>
                <Header
                    key="campaign-training"
                    showInfoButton={false}
                    enableBackNavigation={true}
                    compactMode={true}
                    label={title}>
                        {
                            state.loading? (
                            <div className="spinner">
                                <IonSpinner name="dots" />
                            </div>
                            ) : (
                            <div>
                                <img src={state.campaign?.teaserImage} alt="" />
                            </div>
                            )
                        }
                </Header>

                <div className="campaign-training-container">

                    <div className="campaign-training-title">
                        <Text color={Colors.oceanBlue} size={TextSize.xl} weight={TextWeight.light}>{state.campaign?.title}</Text>
                        <Text color={Colors.oceanBlue} size={TextSize.xl} weight={TextWeight.light}>{state.campaign?.subtitle}</Text>
                    </div>
                    <div className="campaign-training-text">
                        <Text>{state.campaign?.description}</Text>
                    </div>
                    <div className="campaign-training-text">
                        <Text weight={TextWeight.semibold}>Für die Teilnahme an den Schulungen erhälst Du jeweils:</Text>
                    </div>

                    <SegmentedText top={35}>
                        <div className="campaign-training-segmented-text">
                            <Text size={TextSize.l} color={Colors.oceanBlue} weight={TextWeight.semibold} style={{textTransform:"uppercase"}}>{state.campaign?.credits} Punkte</Text>
                        </div>
                    </SegmentedText>

                    <div className="campaign-training-submit-button">
                        <Link className="link-reset" key={state.campaign?.id}
                            to={{
                            pathname: `/campaign-training-proof/${state.campaign?.id}`,
                            state: {title: title}
                            }}>
                            <Button text="Teilnahme bestätigen" onButtonClicked={() => {}}></Button>
                        </Link>

                    </div>
                    {
                  state.campaign?.requestOffer ? (
                    <div>
                      <div className="campaign-display-info-text">
                        <Text weight={TextWeight.semibold}>Ich interessiere mich für die</Text>
                        <Text weight={TextWeight.semibold}>{state.campaign?.title}</Text>
                        <Text weight={TextWeight.semibold}>{state.campaign?.subtitle}</Text>
                      </div>
                      <div className="campaign-display-info-button">
                        <Button kind={ButtonType.neutral} text={requestOfferButtonText() + " anfordern"} onButtonClicked={requestOffer}></Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                }
                </div>
                { status }
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
            </IonContent>
        </IonPage>
    )


}

export default CampaignTrainingDetails