import React, { useState } from 'react'
import "./ProductPicker.css"
import Text, { TextSize, TextWeight } from "../shared/Text"
import { useIonViewDidEnter, IonModal, IonContent } from '@ionic/react';
import { Product } from '../../models/models';
import client from '../../api/client';
import { GET_ALL_PRODUCTS } from '../../api/queries';
import SegmentedText from './SegmentedText';
import Colors from '../../Colors';

interface ProductPickerProps{
    selectedProduct? : Product
    onProductSelected : Function
    onError: Function
}

const ProductModal = (showModal: boolean, productList: Product[], onCloseClick: Function, selectProduct: Function) => {

    function selectProductAndClose(productId: string) {
        selectProduct(productId)
        onCloseClick()
    }

    return (
        <IonModal isOpen={showModal}>
            <IonContent>
            <div className="product-list-container">
                <div className="product-list-close-button">
                    <img src="/assets/images/Close.svg" alt="close" className="product-list-close-button" onClick={() => onCloseClick()}/>
                </div>
                <div className="product-list-title">
                <Text size={TextSize.xl} weight={TextWeight.light}>Produkt wählen</Text>
                </div>
                <div className="product-list-content">
                    {
                    productList.map((product) => (
                        <div key={product.id} onClick={() => selectProductAndClose(product.id)}>
                        <SegmentedText isBottomLine={false}>
                                <Text style={{marginTop:10}}>{product.name}</Text>
                                <Text color={Colors.brownGrey} size={TextSize.s} style={{marginBottom:10}}>{product.portion} | PZN {product.pzn} | UVP {(product.priceCents / 100 ).toFixed(2)} € </Text>
                        </SegmentedText>
                        </div>
                        ))
                    }
                </div>
            </div>
            </IonContent>
        </IonModal>
    )
}

const ProductPicker = ({selectedProduct, onProductSelected, onError} : ProductPickerProps) => {
    const [productList, setProductList] = useState<Product[]>([])
    const [isModalPresented, setIsModalPresented] = useState(false);

    useIonViewDidEnter(async () => {
        await loadAllProducts()
    })

    async function loadAllProducts() {
        const { errors, data } = await client.query({ query: GET_ALL_PRODUCTS })
        if (errors) {
            onError("Es ist ein Fehler beim Laden der Produkte aufgetreten")
            return
        }

        let products: (Product[]|undefined) = undefined
        if (data) {
        products = data?.products.length > 0 ? data?.products : undefined;
        }

        if (products) {
            setProductList(products)
        } else {
            // handle errror case
            onError("Das Produkt konnte nicht gefunden werden.")
        }
    }

    const changeProduct = (selectedId : string) => {
        for(const product of productList) {
            if (product.id === selectedId) {
                onProductSelected(product)
                break;
            }
        }
    }

    function alterModal() {
        setIsModalPresented(!isModalPresented)
      }

    return (
        <div className="product-picker-container">
            <div className="product-picker-text">
                <Text >
                    { selectedProduct ? selectedProduct.productLine.name : "" }
                </Text>
                <Text >
                    { selectedProduct ? selectedProduct.name : "Produkt wählen" }
                </Text>

            </div>
            <img src={"/assets/images/SelectionArrow.svg"} alt="product chooser" className="product-picker-arrow" onClick={alterModal}/>
            {/* <div className="product-picker-arrow" onClick={alterModal}></div> */}
            {ProductModal(isModalPresented, productList, alterModal, changeProduct)}
        </div>
    );


}

export default ProductPicker
