import React from 'react'
import './CampaignCategoryTile.css'
import { CampaignCategory } from '../../models/models'
import Text, { TextSize, TextWeight } from './Text'
import Colors from '../../Colors'


interface CampaignCategoryTileProps {
    campaignCategory : CampaignCategory
}

const CampaignCategoryTile = ({campaignCategory} : CampaignCategoryTileProps) => (

    // <div className="container-box">
    <div className="campaign-category-tile-container">
        <div className="header-line"/>
        <div className="campaign-category-tile">
            <div className="category-title">
                <Text size={TextSize.s} color={Colors.oceanBlue} weight={TextWeight.semibold}>
                    {campaignCategory.title}
                </Text></div>
            <img className="category-image" src={campaignCategory.previewUrl} alt="" />
            <div className="category-points">
            <Text size={TextSize.xs} color={Colors.darkLimeGreen} weight={TextWeight.semibold}>
                {campaignCategory.credits} Punkte
            </Text>
            </div>
        </div>
    </div>
    // </div>
)

export default CampaignCategoryTile