
import Client from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from "apollo-link-context";
import { LS_AUTH_KEY } from '../contexts/UserContext';

// https://github.com/apollographql/apollo-client/issues/4990#issuecomment-504447592
const authLink = setContext((_, {headers, ...context}) => {
  const token = localStorage.getItem(LS_AUTH_KEY);
  return {
    headers: {
      ...headers,
      ...(token ? {Authorization: token} : {}),
    },
    ...context,
  };
});

const client = new Client({
  link: ApolloLink.from([
    onError(({ networkError }) => {
      if  (networkError) {
        console.log(`[Network error]:`, networkError);

        if (networkError && 'response' in networkError) {
          if (networkError.response.status === 401) {
            // dispatch error
            const unauthorizedEvent = new CustomEvent("unauthorized-request")
            window.dispatchEvent(unauthorizedEvent)
          }
        }
      }
    }),
    authLink,
    new HttpLink({
      uri: 'https://galderma.herokuapp.com/graphql'
    })
  ]),
  cache: new InMemoryCache()
});

export default client