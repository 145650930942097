import React, { useState, useContext } from 'react'
import './News.css'
import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react'
import Header from '../components/shared/Header'
import Text, { TextWeight, TextSize } from '../components/shared/Text'
import Colors from '../Colors'
import SegmentedText from '../components/shared/SegmentedText'
import { GET_MESSAGES } from '../api/queries'
import client from '../api/client'
import { Message } from '../models/models'
import { GraphQLError } from 'graphql'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { NewsCountContext } from '../contexts/NewsCountContext'

interface NewsState {
    messages: Message[]
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
  }

const News = () => {

    const [state, setState] = useState<NewsState>({loading: true, errors: undefined, messages: []})

    const newsCountContext = useContext(NewsCountContext)


    async function loadUserData() {
        try {
          const { loading, errors, data } = await client.query({
            query: GET_MESSAGES,
            fetchPolicy: "network-only"
          })

          let messages: Message[] = []
          if (data) {
            messages = data?.messages.length > 0 ? data?.messages : undefined;
          }

          messages.sort((a, b) => {
            if(a.publishedAt < b.publishedAt) return 1
            if(a.publishedAt > b.publishedAt) return -1
            return 0
          })

          newsCountContext.resetCount()
          setState({ messages, errors, loading })
        } catch {
          setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }
      }

      useIonViewDidEnter(() => {
        loadUserData()
      })

    return (
        <IonPage>
            <IonContent>
            <Header key="news"  imageUrl="/assets/images/news.png" showInfoButton={true}></Header>
                <div className="news-container">
                    <div className="news-title">
                        <Text weight={TextWeight.light} size={TextSize.xxl} color={Colors.brownishGrey}>
                            Infos und News
                       </Text>
                    </div>
                    { state.messages.map (message => (
                        <Link className="link-reset" key={message.id}
                        to={{
                          pathname: `/news-details/${message.id}`,
                          state: {
                              title: "Infos und News",
                              message: message
                            }
                        }}>
                            <SegmentedText isTopLine={false}>
                                <div className="news-segmented-container">
                                    <Text color={Colors.oceanBlue} weight={TextWeight.semibold} size={TextSize.s}>
                                        { moment(message.publishedAt).format('DD.MM.YYYY') }
                                    </Text>
                                    <Text color={Colors.brownishGreyTwo} weight={TextWeight.semibold} size={TextSize.l}>
                                        {message.title}
                                    </Text>
                                </div>
                            </SegmentedText>
                        </Link>
                    ))
                    }

                </div>
            </IonContent>
        </IonPage>
    )

}

export default News