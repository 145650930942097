import React, { createContext, useState, useEffect } from "react";
import { Incentive } from "../models/models";
import { useLocalObjectStorage } from "../hooks/useLocalStorage";

interface CartContextValues {
    currentPoints: number
    incentives: [Incentive, number][]
    changedIncentive: Function
    submitIncentiveSuccess: Function
}

export const LS_INCENTIVE_KEY = "gal-incentive"

export const CartContext = createContext<CartContextValues>({
    currentPoints: 0,
    incentives: [],
    changedIncentive: () => console.log("Set Incentive..."),
    submitIncentiveSuccess: () => console.log("Set Incentive...") 
})

interface CartContextProviderProps {
    children: React.ReactNode
  }

const CartContextProvider = ( {children} : CartContextProviderProps) => {
    const [currentPoints, setCurrentPoints] = useState(0);
    const [selectedIncentive, setSelectedIncentive] = useState<[Incentive, number][]>([])
    const [localIncentive, setLocalIncentive] = useLocalObjectStorage(LS_INCENTIVE_KEY)

    useEffect(() => {
        const incentives : (object | null) = localIncentive
        console.log("Ausgabe: Local Incentive: " + localIncentive)
        // let's check for the right type
        if (incentives !== null && incentives instanceof Array) {
          // okay is it any good
          setSelectedIncentive(incentives as [Incentive, number][])
          changePoints(incentives as [Incentive, number][])
        }
      }, [localIncentive])

    const isFirst = (incentive : Incentive) => {

        let filter = selectedIncentive.filter((element:[Incentive, number]) => {
            return element[0].id === incentive.id
            
        })
        return filter.length === 0

    }

    const onIncentiveChanged = (incentive : Incentive, count : number) => {

        if(isFirst(incentive)) {
            let incentives: [Incentive, number][] = [...selectedIncentive, [incentive, 1]]
            changePoints(incentives)
            setLocalIncentive(incentives)
            setSelectedIncentive(incentives)
        } else {
            for (let index in selectedIncentive) {
                const productTuple = selectedIncentive[index]
                if (productTuple[0].id !== incentive.id) {
                    continue;
                }
                // remove product
                if (count === 0) {
                    const incentives: [Incentive, number][] = Object.assign([], selectedIncentive);
                    incentives.splice(Number(index), 1)
                    changePoints(incentives)
                    setLocalIncentive(incentives)
                    setSelectedIncentive(incentives)
                    break;
                }
                // update count
                const incentives: [Incentive, number][] = Object.assign([], selectedIncentive);
                incentives[Number(index)][1] = count
                changePoints(incentives)
                setLocalIncentive(incentives)
                setSelectedIncentive(incentives)
            }
        }
    }

    const changePoints = (incentives : [Incentive, number][]) => {
        let points = 0
        incentives.forEach( (element:[Incentive, number]) => {
            points = points + element[0].credits * element[1]
        })
        setCurrentPoints(points)
    }

    const submitIncentiveSuccess = () => {
        const incentives: [Incentive, number][]  = []
        changePoints(incentives)
        setLocalIncentive(incentives)
        setSelectedIncentive(incentives)
    }

    return (

        <CartContext.Provider value={{currentPoints, incentives: selectedIncentive, changedIncentive: onIncentiveChanged, submitIncentiveSuccess}}>
            { children }
        </CartContext.Provider>
    )

}


export default CartContextProvider