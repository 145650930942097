import React, { useState, useContext, useEffect } from 'react'
import './IncentiveDetails.css'
import { IonPage, IonContent, IonSpinner, IonFooter, IonToast } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { GraphQLError } from 'graphql'
import Header from '../../components/shared/Header'
import { IncentiveCategory, Incentive } from '../../models/models'
import { GET_INCENTIVE_CATEGORY, GET_CURRENT_USER_CREDITS } from '../../api/queries'
import client from '../../api/client'
import Text, { TextSize, TextWeight } from '../../components/shared/Text'
import Colors from '../../Colors'
import SegmentedText from '../../components/shared/SegmentedText'
import IncentiveSelectionTile from '../../components/shared/IncentiveSelectionTile'
import Button from '../../components/shared/Button'
import CartBar from '../../components/shared/CartBar'
import { CartContext } from '../../contexts/CartContext'
import { useHistory } from 'react-router-dom'

interface IncentiveDetailsRouterProps {
    id: string;
  }
  
  interface IncentiveDetailsProps extends RouteComponentProps<IncentiveDetailsRouterProps> {
    location: any
  }
  
  interface IncentiveDetailsState {
    incentiveCategory?: IncentiveCategory
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
    totalCredits?: number
  }

const IncentiveDetails = ({match, location} : IncentiveDetailsProps) => {

    const id = match.params.id;
    let title = ""
    if (location.state) {
      title = location.state.title
    }
    const [error, setError] = useState(<></>)
    const history = useHistory();
    const [state, setState] = useState<IncentiveDetailsState>({loading: true, errors: undefined, incentiveCategory: undefined})
    const cartContext = useContext(CartContext);

    function onError(message: string, duration = 1000) {
      setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
      // make sure that we can reuse our toast
      setTimeout(() => {
          setError(<></>)
      }, duration)
    }

    async function loadIncentiveCategoryDetails() {
        const { loading, errors, data } = await client.query({
          query: GET_INCENTIVE_CATEGORY,
          variables: { id }
        })

        let incentiveCategory: (IncentiveCategory|undefined) = undefined
        if (data) {
          incentiveCategory = data?.incentiveCategories.length > 0 ? data?.incentiveCategories[0] : undefined;
        }

        const userQueryResult = await client.query({
          query: GET_CURRENT_USER_CREDITS,
          fetchPolicy: "network-only"
      })

        setState({ incentiveCategory, errors, loading, totalCredits: userQueryResult.data?.currentUser.credits })
    }
    
    const startLoading = () => {
      loadIncentiveCategoryDetails()
    }

    useEffect(startLoading, [id])

    const incetiveCount = (incentiveId : number) => {
      let filter = cartContext.incentives.filter((element:[Incentive, number]) => {
        return element[0].id === incentiveId
      })
      if(filter.length > 0) {
        let incentTuple = filter[0]
        return incentTuple[1]
      }
      return 0
    
    }

    const onIncentiveChanged = (incentive: Incentive, count : number) => {
      let currentIncentiveCount = incetiveCount(incentive.id)
      if((count > 0 && cartContext.currentPoints + incentive.credits <= (state.totalCredits ? state.totalCredits : 0)) || count === 0 || count < currentIncentiveCount) {
        cartContext.changedIncentive(incentive, count)  
      } else {
        onError("Sie haben nicht genug Punkte um weitere Prämien einzulösen.", 2500)
      }

      
    }

    return (
        <IonPage>
            <IonContent>
                <Header
                    key="incentive-details"
                    showInfoButton={false}
                    enableBackNavigation={true}
                    compactMode={true}
                    label={title}>
                        {
                            state.loading? (
                            <div className="spinner">
                                <IonSpinner name="dots" />
                            </div>
                            ) : (
                            <div>
                                <img src={state.incentiveCategory?.image} alt="" />
                            </div>
                            )
                        }
                </Header>
                <div className="incentive-details-container">
                    <div className="incentive-details-title">
                        <Text size={TextSize.xl} weight={TextWeight.light} color={Colors.oceanBlue}>{state.incentiveCategory?.title}</Text>
                    </div>
                    <div className="incentive-details-description">
                        <Text weight={TextWeight.semibold}>Löse Deine Punkte für Cetaphil<sup>®</sup> Produkte ein.</Text>
                    </div>
                    <SegmentedText top={30} isBottomLine={false}>
                      <div className="incentive-details-segmented-child-container">
                        <Text color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Verfügbare punkte:</Text>
                        <Text>{state.totalCredits}</Text>
                      </div>
                    </SegmentedText>
                    <SegmentedText>
                      <div className="incentive-details-segmented-child-container">
                        <Text color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Punktewert im Warenkorb:</Text>
                      <Text>{cartContext.currentPoints}</Text>
                      </div>
                    </SegmentedText>
                    <div className="incentive-details-description">
                      <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Optionen: </Text> 
                    </div>
                    
                    <div className="incentive-details-list">
                    {
                      state.incentiveCategory?.incentives.map((incentive : Incentive) => (
                        
                        <IncentiveSelectionTile incentive={incentive} incentiveCount={incetiveCount(incentive.id)} onIncentiveCountChanged={onIncentiveChanged}/>
                      ))
                    }
                    </div>
                    <div className="incentive-details-points">
                      <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Ihr Punktestand: </Text> 
                    </div>
                      <div className="incentive-details-text-child-container">
                        <Text color={Colors.oceanBlue} >Vor dem Einlösen:</Text>
                        <Text>{state.totalCredits}</Text>
                      </div>
                    <SegmentedText>
                      <div className="incentive-details-segmented-child-container">
                        <Text color={Colors.oceanBlue} >Nach dem Einlösen:</Text>
                        <Text>{(state.totalCredits? state.totalCredits : 0) - cartContext.currentPoints}</Text>
                      </div>
                    </SegmentedText>

                    <div className="incentive-details-button">
                    <Button text="Punkte jetzt einlösen" onButtonClicked={()=>{ history.push(`/incentive-cart/${state.incentiveCategory?.id}`, { title: "Cetaphil<sup>®</sup> Prämien" }) }}></Button>
                    <Button top={15} text="Weitere Prämie auswählen" onButtonClicked={()=>{ history.goBack() }}></Button>
                    </div>
                    { error }
                </div>
            </IonContent>
            <IonFooter>
              <div onClick={()=>{history.push(`/incentive-cart/${state.incentiveCategory?.id}`, { title: "Cetaphil<sup>®</sup> Prämien" })}}>
              <CartBar currentPoints={cartContext.currentPoints} totalPoints={state.totalCredits? state.totalCredits : 0} filled={cartContext.incentives.length > 0}></CartBar>  
              </div>                
            </IonFooter>
        </IonPage>
      
    )

}

export default IncentiveDetails