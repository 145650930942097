import React from 'react';
import { RouteComponentProps } from 'react-router';
import { CampaignCategoryData, CampaignCategory, CategoryType, Campaign } from '../../models/models';
import { useQuery } from '@apollo/react-hooks';
import { IonPage, IonContent, IonSpinner } from '@ionic/react';
import Header from '../../components/shared/Header';
import CampaignTile from '../../components/shared/CampaignTile';
import Text, { TextWeight, TextSize } from '../../components/shared/Text';
import './CampaignListing.css'
import { Link } from 'react-router-dom';
import { GET_CAMPAIGN_CATEGORY } from '../../api/queries';

interface CampaignListingRouterProps {
  id: string;
}

// pretty neat - https://stackoverflow.com/questions/44118060/react-router-dom-with-typescript
interface CampaignListingProps extends RouteComponentProps<CampaignListingRouterProps> {

}

interface CampaignCategoryVars {
  id: string;
}

const CampaignListing: React.FC<CampaignListingProps> = ({ match }: CampaignListingProps) => {
  const id = match.params.id
  const { loading, error, data } = useQuery<CampaignCategoryData, CampaignCategoryVars>(GET_CAMPAIGN_CATEGORY, { variables: { id } })

  let campaignCategory: (CampaignCategory|undefined) = undefined
  if (data) {
    campaignCategory = data?.campaignCategories.length > 0 ? data?.campaignCategories[0] : undefined;
  }

  if (loading) {
    return (
        <div>
          <div className="spinner">
            <IonSpinner name="dots" />
          </div>
        </div>
    )
  }

  function openCategory(category: CampaignCategory, campaign: Campaign) {
    switch(category.categoryType) {
      case CategoryType.promotion:
      case CategoryType.supply:
      case CategoryType.display:
        return (
          <Link className="link-reset" key={`display-link-${campaign.id}`}
            to={{
              pathname: `/campaign-display/${campaign.id}`,
              state: {title: category.title}
            }}>
            <CampaignTile key={`display-tile-${campaign.id}`} campaign={campaign} />
          </Link>
        )
      case CategoryType.training:
        return (
          <Link className="link-reset" key={`training-link-${campaign.id}-${Math.random()}`}
            to={{
              pathname: `/campaign-training/${campaign.id}`,
              state: {title: category.title}
            }}>
            <CampaignTile key={`training-tile-${campaign.id}`} campaign={campaign} />
          </Link>
        )
    }
  }

  return (
    <IonPage>
      <IonContent>
        <Header key="campaign-listing"
                showInfoButton={true}
                imageUrl="/assets/images/HomeProductTeaser@3x.png"
                enableBackNavigation={true}
                compactMode={false} />
        {
          loading ? (
            <div>
              <div className="spinner">
                <IonSpinner name="dots" />
              </div>
            </div>
          ) : <></>
        }
        {
          error ? (
            <span>
              { error.message }
            </span>) : <></>
        }

        {
          campaignCategory ? (
            <>
              <div className="heading">
                <Text weight={TextWeight.light} size={TextSize.xl}>
                  {campaignCategory.title}
                </Text>
              </div>
              { campaignCategory.description.length > 0 ? 
              (<div className="campaing-listing-description">
                <Text>{campaignCategory?.description}</Text>
              </div>)
              : 
              (<></>)}
              {
                campaignCategory.campaigns.sort((a, b) => a.order - b.order).map((campaign) => (
                  openCategory(campaignCategory!, campaign)
                ))
              }

            </>
          ) : (
            <p>Es konnten leider keine Informationen zu dieser Kategorie gefunden werden</p>
          )
        }

      </IonContent>
    </IonPage>
  );
}

export default CampaignListing;