import React from 'react'
import './CartBar.css'
import Text, { TextWeight } from './Text'
import Colors from '../../Colors'

interface CartBarProps {
    totalPoints: number
    currentPoints: number
    filled?: boolean 
}

const CartBar = ({currentPoints, totalPoints, filled = false} : CartBarProps) => {

    return (

        <div className="cart-bar-container">
            <div className="cart-bar-content">
                <Text weight={TextWeight.semibold} color={Colors.white} style={{textTransform:"uppercase"}}>{currentPoints}/{totalPoints} Punkten im Warenkorb</Text>
                <div className="cart-bar-image-container">
                    <img className="" src={ filled ? "/assets/images/CardFilled.svg" : "/assets/images/Card.svg"} alt="Card" />
                </div>
            </div>
        </div>

    )

}

export default CartBar
