import React from 'react'
import './IncentiveCategoryTile.css'
import { IncentiveCategory } from '../../models/models'
import Text, { TextSize, TextWeight } from './Text'
import Colors from '../../Colors'

interface IncentiveCategoryTileProps {
    incentiveCategory : IncentiveCategory
}

const IncentiveCategoryTile = ({incentiveCategory} : IncentiveCategoryTileProps) => {

    return(
    // <div style={{width:580}}>
    <div className="incentive-category-tile-container">
        <div className="header-line"/>
        <div className="incentive-category-tile">
            <div className="incentive-category-title">
                <Text size={TextSize.xs} color={Colors.oceanBlue} weight={TextWeight.semibold}>
                    {incentiveCategory.title}
                </Text></div>
            <img className="incentive-category-image" src={incentiveCategory.image} alt="" />
          
        </div>
    </div>
    // </div>
    )
}

export default IncentiveCategoryTile