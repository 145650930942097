import React from 'react'
import './ImageThumbnail.css'
interface ImageThumbnailProps {
  onDeleteClicked: Function
  imageData: string
  index: number
}

const ImageThumbnail = ({imageData, onDeleteClicked, index}: ImageThumbnailProps) => {
  return (
      <div className="image-thumbnail-container">
          <img className="image-thumbnail-delete" src="/assets/images/DeleteButton.svg" alt="Delete" onClick={() => onDeleteClicked(index)}/>
          <img className="image-thumbnail-image" src={imageData} alt=""></img>
          
      </div>
  )
}

export default ImageThumbnail;