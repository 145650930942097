import React from 'react'
import DateSelect from './DateSelect'
import Text from './Text'
import './DateRangeSelect.css'

interface DateRangeSelectProps {
    rangeMin? : string
    rangeMax? : string
    fromDate : string
    toDate : string
    onChangedFromDate : Function
    onChangedToDate : Function
}


const DateRangeSelect = ({fromDate, toDate, rangeMin = fromDate, rangeMax = toDate, onChangedFromDate, onChangedToDate} : DateRangeSelectProps) => {

    return (
    <div className="date-range-select-container">
        <DateSelect min={rangeMin} max={(toDate < rangeMax) ? toDate : rangeMax} value={fromDate} onDateChanged={ (value: string) => {
            onChangedFromDate(value)
        }}></DateSelect>
        <Text> bis</Text>
        <DateSelect min={(fromDate > rangeMin) ? fromDate : rangeMin} max={rangeMax} value={toDate} onDateChanged={(value: string) => {
            onChangedToDate(value)
        }
        }></DateSelect>
    </div>
    )
}

export default DateRangeSelect