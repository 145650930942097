enum Colors {
    brownishGrey = "#6e6e6e",
    brownishGreyTwo = "#707070",
    oceanBlue = "#0078a7",
    darkLimeGreen = "#84bd00",
    very_light_pink = "#b7b7b7",
    white = "#ffffff",
    brownGrey = "#9b9b9b",
    darkLime = "#77aa00"
}

export default Colors