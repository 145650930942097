import React, { useState } from 'react'
import './NewsDetails.css'
import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import Header from '../components/shared/Header'
import { Message } from '../models/models'
import Text, { TextWeight, TextSize } from '../components/shared/Text'
import moment from 'moment'
import Colors from '../Colors'
import { GraphQLError } from 'graphql'
import { GET_MESSAGE } from '../api/queries'
import client from '../api/client'

interface NewsDetailsRouterProps {
  id: string;
}

interface NewsDetailsProps extends RouteComponentProps<NewsDetailsRouterProps> {
  location: any
}

interface NewsDetailsState {
  message?: Message
  errors?: ReadonlyArray<GraphQLError>
  loading: boolean
}

const NewsDetails = ({match, location} : NewsDetailsProps) => {

  const id = match.params.id 
  const [state, setState] = useState<NewsDetailsState>({loading: true, errors: undefined, message: undefined})

  async function loadUserData() {
    try {
      const { loading, errors, data } = await client.query({
        query: GET_MESSAGE,
        variables: { id },
        fetchPolicy: "network-only"
      })

      let message: (Message | undefined) = undefined
      if (data) {
        message = data?.messages.length > 0 ? data?.messages[0] : undefined;
      }

      setState({ message, errors, loading })
    } catch {
      setState({...state, errors: [new GraphQLError("Error while Loading")]})
    }
  }

  useIonViewDidEnter(() => {
    loadUserData()
  })

  let title = ""
  let message: (Message|undefined) = undefined
  if (location.state) {
    title = location.state.title ?? "Infos & News"
    message = location.state.message
  }

  return (
    <IonPage>
      <IonContent>
      <Header
                    key="news-details"
                    showInfoButton={false}
                    enableBackNavigation={true}
                    compactMode={true}
                    label={title}>
                      <div>
                        <img src={message?.teaserImage ?? state.message?.teaserImage} alt="" />
                      </div>
                </Header>
                <div className="news-details-container">
                    <Text color={Colors.oceanBlue} weight={TextWeight.semibold} size={TextSize.s}>
                     { moment(message?.publishedAt ?? state.message?.publishedAt).format('DD.MM.YYYY') }
                    </Text>
                    <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl}>
                      {message?.title ?? state.message?.title}
                    </Text>
                    <div className="news-details-content">
                      <Text>
                        {message?.content ?? state.message?.content}
                      </Text>
                    </div>
                </div>
      </IonContent>
    </IonPage>

  )

}

export default NewsDetails