import React, { useState } from 'react'
import "./ProductChooser.css"
import Text, { TextWeight } from './Text'
import Button, {ButtonType} from './Button'
import ProductPicker from './ProductPicker'
import { Product } from '../../models/models';

interface ProductChooserProps {
    onProductSelected: Function
    onError: Function
}

const ProductChooser = ({onProductSelected, onError}: ProductChooserProps) => {
    const [selectedProduct, setSelectedProduct] = useState<(Product|undefined)>()

    function onSubmitProductSelection() {
        if (selectedProduct) {
            onProductSelected(selectedProduct)
            setSelectedProduct(undefined)
        } else {
            onError("Bitte wählen Sie ein Produkt aus.")
        }
    }

    function onProductPickerSelect(product: Product) {
        setSelectedProduct(product)
    }

    return(

        <div className="product-chooser-container">
            <Text weight={TextWeight.semibold}> per Auswahl hinzufügen: </Text>
            <ProductPicker selectedProduct={selectedProduct} onProductSelected={onProductPickerSelect} onError={onError} />
            <Button kind={ButtonType.neutral}
                    text="Auswahl übernehmen"
                    top={20}
                    bottom={20}
                    onButtonClicked={onSubmitProductSelection}/>
        </div>
    );


}

export default ProductChooser