import React, { useContext, useState } from 'react'
import './IncentiveCart.css'
import { IonPage, IonContent, useIonViewDidEnter, IonToast, IonLoading, IonBackdrop } from '@ionic/react'
import Header from '../../components/shared/Header'
import { RouteComponentProps } from 'react-router'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'
import Colors from '../../Colors'
import { CartContext } from '../../contexts/CartContext'
import IncentiveSelectionTile from '../../components/shared/IncentiveSelectionTile'
import { Incentive } from '../../models/models'
import SegmentedText from '../../components/shared/SegmentedText'
import client from '../../api/client'
import { GET_CURRENT_USER_CREDITS } from '../../api/queries'
import Button from '../../components/shared/Button'
import { SUBMIT_INCENTIVE_MUTATION } from '../../api/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

interface IncentiveCartRouterProps {
    id: string;
  }
  
  interface IncentiveDetailsProps extends RouteComponentProps<IncentiveCartRouterProps> {
    location: any
  }

const IncentiveCart = ({location} : IncentiveDetailsProps) => {
    const cartContext = useContext(CartContext);
    const [totalPoints, setTotalPoints] = useState(0)
    const [error, setError] = useState(<></>)
    const history = useHistory();
    const [mutate] = useMutation(SUBMIT_INCENTIVE_MUTATION)
    const [showLoading, setShowLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    
      let title = ""
    if (location.state) {
      title = location.state.title
    }

    async function loadData() {
      const userQueryResult  = await client.query({
        query: GET_CURRENT_USER_CREDITS
      })

      setTotalPoints(userQueryResult.data?.currentUser.credits)
    }

 
    useIonViewDidEnter(() => {
      loadData()
    })

    const incentiveCount = (incentiveId : number) => {
      let filter = cartContext.incentives.filter((element:[Incentive, number]) => {
        return element[0].id === incentiveId
      })
      if(filter.length > 0) {
        let incentTuple = filter[0]
        return incentTuple[1]
      }
      return 0
    
    }

    function onError(message: string, duration = 1000) {
      setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
      // make sure that we can reuse our toast
      setTimeout(() => {
          setError(<></>)
      }, duration)
    }

    const onIncentiveChanged = (incentive: Incentive, count : number) => {
        let currentIncentiveCount = incentiveCount(incentive.id)
        if((count > 0 && cartContext.currentPoints + incentive.credits <= totalPoints) || count === 0 || count < currentIncentiveCount) {
          cartContext.changedIncentive(incentive, count)  
        } else {
          onError("Sie haben nicht genug Punkte um weitere Prämien einzulösen.", 2500)
        }
      }

      const onSubmit = async () => {
       
        try {
            setShowLoading(true)
            await mutate({
                variables: {
                  creditsTotal: cartContext.currentPoints,
                  incentives: cartContext.incentives.map((incentiveTuple) => {
                    return { incentiveId: incentiveTuple[0].id, count: incentiveTuple[1] }
                  })
                }
            })
            setShowLoading(false)
            cartContext.submitIncentiveSuccess()
            setShowSuccess(true)
        } catch (error) {
          console.log("error: " + error)
            setShowLoading(false)
            onError("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
        }

        console.log("done")
    }
    
    const onClose = () => {
        setShowSuccess(false)
        history.replace("/incentive-overview")
    }


    return (
        <IonPage>
            <IonContent> 
                <div className="incentive-cart-container">
                { showSuccess? ( 
                  <div className="">
                    <div className="incentive-cart-success">
                      <img className="" src="/assets/images/Check.svg" alt="Check"></img>
                      <Text color={Colors.oceanBlue} weight={TextWeight.semibold}>Punkte erfolgreich eingelöst</Text>
                    </div>
                    <IonBackdrop style={{opacity:0.3, zIndex:11}} onIonBackdropTap={onClose}/>
                    </div>
                    ) : (
                    <></>
                  )} 
                <div>
                <Header
                    key="incentive-cart"
                    showInfoButton={false}
                    enableBackNavigation={true}
                    compactMode={true}
                    label={title}
                    >
                           <div>
                                <img src="/assets/images/IncentiveTeaser2.jpg" alt="" />
                          </div>
                </Header>    
                    <div className="incentive-cart-title">
                        <Text weight={TextWeight.light} color={Colors.oceanBlue} size={TextSize.xl}>Deine Auswahl bestätigen</Text>
                    </div>
                    <div className="incentive-cart-list">
                        {
                            cartContext.incentives.map(incetive => (
                                <IncentiveSelectionTile key={incetive[0].id} incentive={incetive[0]} incentiveCount={incetive[1]} onIncentiveCountChanged={onIncentiveChanged}/>   
                            ))
                        }
                    </div>
                    <SegmentedText top={30} isBottomLine={false}>
                      <div className="incentive-details-segmented-child-container">
                        <Text color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Verfügbare punkte:</Text>
                        <Text>{totalPoints}</Text>
                      </div>
                    </SegmentedText>
                    <SegmentedText>
                      <div className="incentive-details-segmented-child-container">
                        <Text color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>Punktewert im Warenkorb:</Text>
                        <Text>{cartContext.currentPoints}</Text>
                      </div>
                    </SegmentedText>
                    <div className="incentive-cart-buttons">
                      <Button text="Punkte jetzt einlösen" onButtonClicked={onSubmit}></Button>
                      <Button top={15} text="Abbrechen" onButtonClicked={()=>{ 
                        history.replace("/incentive-overview")
                        }}></Button>
                    </div>
                    { error }
                    <IonLoading
                      isOpen={showLoading}
                      onDidDismiss={() => setShowLoading(false)}
                      message={'Daten werden übertragen...'}
                    />

                </div>
                </div>
            </IonContent>
        </IonPage>
    )

}

export default IncentiveCart