import React, { useState } from 'react'
import { Product } from '../../models/models'
import Text, { TextSize, TextWeight } from './Text'
import './ProductSelectionTile.css'
import Colors from '../../Colors'

interface ProductSelectionTileProps {
    product : Product
    onProductCountChanged : Function
}

const ProductSelectionTile = ({product, onProductCountChanged} : ProductSelectionTileProps) => {

    const [productCount, setProductCount] = useState(1)

    const onProductCountChangedUp = () => {
        const newCount = productCount + 1
        setProductCount(newCount)
        onProductCountChanged(product, newCount)
    }

    const onProductCountChangedDown = () => {
        const newCount = productCount - 1
        setProductCount(newCount)
        onProductCountChanged(product, newCount)
    }

    return (
        <div className="production-selection-tile-container">
            <div className="production-selection-tile-image-container">
                <img className="production-selection-tile-image" src={product?.preview} alt="" />
                <Text size={TextSize.s}>{product.portion}</Text>
            </div>

            <div className="production-selection-tile-text-container">
                <div className="production-selection-tile-header-title">
                    <Text size={TextSize.xxs}>{product?.productLine.name}</Text>
                    <Text size={TextSize.s} weight={TextWeight.semibold}>{product?.name}</Text>
                </div>
                <div>
                    <Text color={Colors.brownGrey} size={TextSize.s}> PZN {product?.pzn} | UVP {(product.priceCents / 100 ).toFixed(2)} €</Text>
                </div>
            </div>
            <div className="production-selection-tile-counting-container">
                <div className="production-selection-tile-line"></div>
                <div className="production-selection-tile-counter-container">
                    <div onClick={onProductCountChangedUp}>
                        <img className="production-selection-tile-counter-arrow-up" src="/assets/images/CounterArrowUp.svg" alt="Counter Up" />
                    </div>
                    <div className="production-selection-tile-counter-number">
                        <Text color={Colors.oceanBlue} size={TextSize.xl}>{productCount}</Text>
                    </div>
                    <div onClick={onProductCountChangedDown}>
                        <img className="production-selection-tile-counter-arrow-down" src="/assets/images/CounterArrowDown.svg" alt="Counter Down" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProductSelectionTile