import React, { createContext, useEffect, useContext } from "react";
import { useLocalNumberStorage } from "../hooks/useLocalStorage";
import client from "../api/client";
import { GET_MESSAGES } from "../api/queries";
import { Message } from "../models/models";
import { UserContext } from "./UserContext";

interface NewsCountContextValues {
    newsCount: number | null
    resetCount: Function
    loadMessagesData: Function
}

export const LS_NEWS_COUNT_KEY = "gal-news-count"
export const LS_NEWS_UPDATETIME_KEY = "gal-news-update-time"

export const NewsCountContext = createContext<NewsCountContextValues>({
    newsCount: 0,
    resetCount: () => console.log("changedNewsCount..."),
    loadMessagesData: () => console.log("loadMessagesData..."),
})

interface NewsCountContextProviderProps {
    children: React.ReactNode
  }

const NewsCountContextProvider = ( {children} : NewsCountContextProviderProps) => {
    const userContext = useContext(UserContext);
    const [newsCount, setNewsCount] = useLocalNumberStorage(LS_NEWS_COUNT_KEY)

    async function loadMessagesData() {
        let lastSeenNewsDate = localStorage.getItem(LS_NEWS_UPDATETIME_KEY)
        try {
          const { data } = await client.query({
            query: GET_MESSAGES,
            fetchPolicy: "network-only"
          })

          // extracting messages from response
          let messages: Message[] = []
          if (data) {
            messages = data?.messages.length > 0 ? data?.messages : [];
          }

          // if we've never hit an update all messages are new
          if (lastSeenNewsDate === null) {
            changedNewsCount(messages.length)
            return
          }

          // lets pick the new ones and sort them by their published date
          const newMessages = messages.filter((message) => new Date(message.publishedAt) > new Date(lastSeenNewsDate!))
                                      .sort((messageA, messageB) => new Date(messageB.publishedAt).getTime() - new Date(messageA.publishedAt).getTime())

          if (newMessages.length > 0)
            changedNewsCount(newMessages.length)
          else {
            changedNewsCount(0)
          }
        } catch {
          console.log("Error while retrieving messages...")
        }
      }

      useEffect(() => {
        const timer = setInterval(async () => {
          if(userContext.isLoggedIn) {
            await loadMessagesData()
          }

        }, 5000);
        return () => clearTimeout(timer);
      });

    const changedNewsCount = (count : number) => {
        if (count === 0 ||  count === undefined) {
            setNewsCount(0)
        } else {
            setNewsCount(count)
        }
    }

    const resetCount = () => {
      setNewsCount(0)
      localStorage.setItem(LS_NEWS_UPDATETIME_KEY, new Date().toString())
    }

    return (
        <NewsCountContext.Provider value={{newsCount, resetCount, loadMessagesData}}>
            { children }
        </NewsCountContext.Provider>
    )

}


export default NewsCountContextProvider
