import React, { createContext, useState, useEffect } from 'react';

// Pretty neat reference https://www.carlrippon.com/react-context-with-typescript-p1/
interface UserContextValues {
  token: string
  setToken: Function
  isLoggedIn: boolean
  logout: Function
}

export const UserContext = createContext<UserContextValues>({
  token: "",
  setToken: () => console.log("Set Token..."),
  isLoggedIn: true,
  logout: () => console.log("Logout")
})

interface UserContextProviderProps {
  children: React.ReactNode
}

export const LS_AUTH_KEY = "gal-auth-token"

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [token, setToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  useEffect(() => {
    const savedToken = localStorage.getItem(LS_AUTH_KEY);
    // let's check for the right type
    if (typeof savedToken === "string") {
      // okay is it any good
      if (savedToken === "" || savedToken === null) {
        setIsLoggedIn(false)
        return
      }
      setToken(savedToken)
    } else {
      setIsLoggedIn(false)
      return
    }
  }, [])

  function handleUnauthorizedEvent() {
    console.log("Unauthorized Event")
    localStorage.setItem(LS_AUTH_KEY, "")
    setIsLoggedIn(false)
  }

  useEffect(() => {
    window.addEventListener('unauthorized-request', handleUnauthorizedEvent);

    return () => {
      window.removeEventListener('unauthorized-request', handleUnauthorizedEvent);
    };
  }, []);

  function setInternalToken(token: string) {
    setToken(token);
    localStorage.setItem(LS_AUTH_KEY, token)
    setIsLoggedIn(true)
  }

  function logout() {
    setInternalToken("")
    setIsLoggedIn(false)
  }

  return(
    <UserContext.Provider value={{token, setToken: setInternalToken, isLoggedIn, logout}}>
      { children }
    </UserContext.Provider>
  )
}

export default UserContextProvider
