import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonSpinner } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Campaign, CampaignCategory } from '../../models/models'
import { GraphQLError } from 'graphql'
import Header from '../../components/shared/Header'
import client from '../../api/client'
import { GET_CAMPAIGN_CATEGORY } from '../../api/queries'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'
import './CampaignPlacement.css'
import SegmentedText from '../../components/shared/SegmentedText'
import moment from 'moment'
import Button from '../../components/shared/Button'
import { Link } from 'react-router-dom'
import Colors from '../../Colors'

interface CampaignPlacementRouterProps {
    id: string;
  }

interface CampaignPlacementProps extends RouteComponentProps<CampaignPlacementRouterProps> {
    location: any
  }

  interface CampaignPlacementState {
    category?: CampaignCategory
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
  }


const CampaignPlacement = ({match, location} : CampaignPlacementProps) => {

    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }
    const [state, setState] = useState<CampaignPlacementState>({loading: true, errors: undefined, campaign: undefined})

    async function loadCampaignDetails() {
        const { loading, errors, data } = await client.query({
          query: GET_CAMPAIGN_CATEGORY,
          variables: { id }
        })


        let category: (CampaignCategory|undefined) = undefined
        if (data) {
            category = data?.campaignCategories.length > 0 ? data?.campaignCategories[0] : undefined;
        }

        let campaign: (Campaign|undefined) = undefined
        if (category) {
          campaign = category.campaigns.length > 0 ? category.campaigns[0] : undefined;
        }

        setState({category, campaign, errors, loading })
      }

    const startLoading = () => {
        loadCampaignDetails()
    }

    useEffect(startLoading, [id])

    return(
        <IonPage>
            <IonContent>
            <Header
                key="campaign-placement"
                showInfoButton={false}
                enableBackNavigation={true}
                compactMode={true}
                label={title}>
                    {
                    state.loading? (
                        <div className="spinner">
                            <IonSpinner name="dots" />
                        </div>
                    ) : (
                        <div>
                           <img src={state.campaign?.teaserImage} alt="" />
                        </div>
                    )
                    }
                </Header>
                <div className="campaign-placement-container">
                    <div className="campaign-placement-title">
                        <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl}>{state.campaign?.title}</Text>
                        <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl}>{state.campaign?.subtitle}</Text>
                    </div>
                    <div className="campaign-placement-description">
                    <Text>{state.campaign?.description}</Text>
                    </div>
                    <div className="campaign-placement-text-code">
                        <Text color={Colors.oceanBlue} weight={TextWeight.semibold} size={TextSize.s}>DETAILS:</Text>
                    </div>
                    <SegmentedText isBottomLine={false}>
                        <div className="campaign-placement-segmented-container">
                            <Text>Zeitraum: </Text>
                            {
                              state.campaign?.startsAt !== undefined ? (
                                <Text>{moment(state.campaign?.startsAt).format('DD.MM.YY')} - {moment(state.campaign?.expiresAt).format('DD.MM.YY')}</Text> 
                              ):(
                                <Text>unbegrenzt</Text>
                              )
                            } 
                        </div>
                    </SegmentedText>
                    <SegmentedText>
                        <div className="campaign-placement-segmented-container">
                            <Text>Zeitraum: </Text>    
                            <Text>{state.campaign?.credits}</Text> 
                        </div>
                    </SegmentedText>
                    <div className="campaign-placement-button">
                        <Link className="link-reset" key="campaign-placement-proof"
                            to={{
                            pathname: `/campaign-placement-proof/${state.campaign?.id}`,
                            state: {title: title}
                            }}>
                            <Button text="Nachweis einreichen" onButtonClicked={()=>{}}/>
                        </Link>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )

}

export default CampaignPlacement