import React, { useState, useEffect } from "react"
import { RouteComponentProps } from 'react-router'
import { IonPage, IonContent, IonToast, IonLoading } from "@ionic/react"
import Header from "../../components/shared/Header"
import Text, { TextWeight, TextSize } from "../../components/shared/Text"
import "./CampaignDisplaysProof.css"
import SegmentedText from "../../components/shared/SegmentedText"
import DateRangeSelect from "../../components/shared/DateRangeSelect"
import ProductImagePicker from "../../components/shared/ProductImagePicker"
import Button, { ButtonType } from "../../components/shared/Button"
import ImageThumbnail from "../../components/shared/ImageThumbnail"
import { Campaign } from '../../models/models'
import { GraphQLError } from 'graphql'
import client from "../../api/client"
import { GET_CAMPAIGN } from "../../api/queries"
import Colors from "../../Colors"
import { useMutation } from "@apollo/react-hooks"
import { PROOF_OF_DISPLAY_MUTATION } from "../../api/mutations"
import { useHistory } from "react-router-dom"

interface CampaignDisplaysProofRouterProps {
    id: string;
  }

interface CampaignDisplaysProofProps extends RouteComponentProps<CampaignDisplaysProofRouterProps> {
    location: any
}

interface CampaignDisplaysProofState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
}


const CampaignDisplaysProof = ({match, location} : CampaignDisplaysProofProps) => {
    const id = match.params.id;
    let title = ""
    if (location.state) {
        title = location.state.title
    }

    const [state, setState] = useState<CampaignDisplaysProofState>({loading: true, errors: undefined, campaign: undefined})

    const [fromDate, setFromDate] = useState("2020-02-21")
    const [toDate, setToDate] = useState("2020-02-21")
    const [selectedImages, setImages] = useState<(string[])>([])
    const [error, setError] = useState(<></>)
    const [mutate] = useMutation(PROOF_OF_DISPLAY_MUTATION)
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();

    const onChangedFromDate = (value : string) => {
        setFromDate(value)
    }

    const onChangedToDate = (value : string) => {
        setToDate(value)
    }

    const onImageChosen = (imageData: string) => setImages([...selectedImages, imageData])

    const onImageDelete = (index: number) => {
        const images = Object.assign([], selectedImages);
        images.splice(index, 1)
        setImages(images)
    }

    const onError = (message: string, duration = 1000) => {
        setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
            setError(<></>)
        }, duration)
    }

    const onSubmit = async () => {
     
        const campainId = state.campaign?.id;
        try {
            setShowLoading(true)
            await mutate({
                variables: {
                    campaignId: campainId,
                    files: selectedImages,
                    fromDate: fromDate,
                    tillDate: toDate,
                }
            })
            setShowLoading(false)
            history.replace(`/campaign-proof-done/${campainId}`, { title })
        } catch (error) {
            setShowLoading(false)
            onError("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
        }
    }

    async function loadCampaignDetails() {
        try {
            const { loading, errors, data } = await client.query({
                query: GET_CAMPAIGN,
                variables: { id }
            })

            let campaign: (Campaign|undefined) = undefined
            if (data) {
                campaign = data?.campaigns.length > 0 ? data?.campaigns[0] : undefined;
            }

            if(campaign?.startsAt !== undefined) {
                setFromDate(campaign?.startsAt)
            }

            if(campaign?.expiresAt !== undefined) {
                setToDate(campaign?.expiresAt)
            }

            setError(<></>)
            setState({ campaign, errors, loading })
        } catch {
            setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }
    }

    const startLoading = () => {
        loadCampaignDetails()
    }

    useEffect(startLoading, [id])

    return (
        <IonPage>
            <IonContent>
            <Header
            key="campaign-display-proof"
            showInfoButton={false}
            enableBackNavigation={true}
            compactMode={true}
            label={title}>
                <></>
            </Header>

                <div className="campaign-display-proof-container">
                    <div className="campaign-display-proof-title">
                        <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl} style={{letterSpacing:0}}>
                            Nachweis einreichen
                        </Text>
                    </div>
                    <div>
                    <SegmentedText top={40} bottom={40}>
                        <div className="campaign-display-proof-segmend">
                        <Text style={{marginRight:5}} >
                            Für:
                        </Text>
                        <div className="campaign-display-proof-segmend-title">
                        <Text >
                            {state.campaign?.title}
                        </Text>
                        <Text style={{marginLeft:5}} >
                            {state.campaign?.subtitle}
                        </Text>
                        </div>
                        </div>
                    </SegmentedText>
                    </div>
                    <div style={{marginLeft:20, marginTop:20}}>
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            1. Zeitraum für Nachweis angeben:
                        </Text>
                    </div>


                    <DateRangeSelect fromDate={fromDate} toDate={toDate} onChangedFromDate={onChangedFromDate} onChangedToDate={onChangedToDate}></DateRangeSelect>


                    <div style={{marginLeft:20, marginTop:40, marginBottom:10}}>
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            2. Fotonachweis
                        </Text>
                    </div>

                    <div>
                        {
                            selectedImages.map((image, index) => (
                                <ImageThumbnail key={index} imageData={image} index={index} onDeleteClicked={onImageDelete} />
                            ))
                        }
                        {
                        (selectedImages.length > 0) ? (
                            <div className="campaign-display-more-images-text">
                                <Text weight={TextWeight.semibold} color={Colors.brownGrey}>Weiteres Foto hinzufügen:</Text>
                            </div>
                        ) : (<></>)
                        }
                    </div>

                    <div className="campaign-display-proof-image-selection">
                        <ProductImagePicker onImageChosen={onImageChosen} />
                    </div>
                </div>


                <div className="campaign-display-proof-submit-button-container">
                <Button kind={ButtonType.positiv} text="Nachweis senden" top={20} bottom={20} onButtonClicked={onSubmit}></Button>
                </div>
                    { error }
                    <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
            </IonContent>
        </IonPage>
    )
}

export default CampaignDisplaysProof