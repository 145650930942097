import React, { useState, useEffect } from "react"
import { RouteComponentProps } from 'react-router'
import { IonPage, IonContent, IonToast, IonLoading } from "@ionic/react"
import Header from "../../components/shared/Header"
import Text, { TextWeight, TextSize } from "../../components/shared/Text"
import "./CampaignSale.css"
import SegmentedText from "../../components/shared/SegmentedText"
import DateRangeSelect from "../../components/shared/DateRangeSelect"
import PZNPicker from "../../components/shared/PZNPicker"
import ProductChooser from "../../components/shared/ProductChooser"
import { Product, Campaign } from "../../models/models"
import ProductImagePicker from "../../components/shared/ProductImagePicker"
import Button, { ButtonType } from "../../components/shared/Button"
import ProductSelectionTile from '../../components/shared/ProductSelectionTile'
import { useMutation } from "@apollo/react-hooks"
import ImageThumbnail from "../../components/shared/ImageThumbnail"
import { GraphQLError } from "graphql"
import client from "../../api/client"
import { GET_CAMPAIGN } from "../../api/queries"
import { PROOF_OF_COOPERATION_MUTATION } from "../../api/mutations"
import Colors from "../../Colors"
import { useHistory } from "react-router-dom"

interface CampaignSaleRouterProps {
    id: string;
  }

interface CampaignSaleProps extends RouteComponentProps<CampaignSaleRouterProps> {
    location: any
}

interface CampaignSaleState {
    campaign?: Campaign
    errors?: ReadonlyArray<GraphQLError>
    loading: boolean
}



const CampaignSale = ({match, location} : CampaignSaleProps) => {
    const id = match.params.id;
    let title = ""
    let credits = 0  
    if (location.state) {
        title = location.state.title
    }

    const [state, setState] = useState<CampaignSaleState>({loading: true, errors: undefined, campaign: undefined})

    const [fromDate, setFromDate] = useState("2020-02-21")
    const [toDate, setToDate] = useState("2020-02-21")
    const [selectedProducts, setSelectedProducts] = useState<[Product, number][]>([])
    const [selectedImages, setImages] = useState<(string[])>([])
    const [error, setError] = useState(<></>)
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false)

    const offerResult = (res : any) => {
        credits = res.proofSale.proof.credits
        setShowLoading(false)
        history.replace(`/campaign-proof-done/${state.campaign?.id}`, { title, credits })
    }

    const [mutate] = useMutation(PROOF_OF_COOPERATION_MUTATION, {onCompleted: offerResult})
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();

    async function loadCampaignDetails() {
        try {
            const { loading, errors, data } = await client.query({
                query: GET_CAMPAIGN,
                variables: { id }
            })

            let campaign: (Campaign|undefined) = undefined
            if (data) {
            campaign = data?.campaigns.length > 0 ? data?.campaigns[0] : undefined;
            }

            if(campaign?.startsAt !== undefined) {
                setFromDate(campaign?.startsAt)
            }

            if(campaign?.expiresAt !== undefined) {
                setToDate(campaign?.expiresAt)
            }

            setState({ campaign, errors, loading })
        } catch {
            setState({...state, errors: [new GraphQLError("Error while Loading")]})
        }

    }

    const startLoading = () => {
        loadCampaignDetails()
    }

    useEffect(startLoading, [id])

    const onChangedFromDate = (value : string) => {
        setFromDate(value)
    }

    const onChangedToDate = (value : string) => {
        setToDate(value)
    }

    const onProductSelected = (product: Product) => {
        // product is already selected?
        // filtering through indexOf doesn't work. I guess because of some Type Foo
        for (const productTuple of selectedProducts) {
            if (productTuple[0].id === product.id) {
                onError("Sie haben das Produkt bereits hinzugefügt.")
                return
            }
        }

        setSelectedProducts([...selectedProducts, [product, 1]])
    }

    const onProductChanged = (product : Product, count : number) => {
        // check if product is already in list
        for (let index in selectedProducts) {
            console.log(index)
            const productTuple = selectedProducts[index]

            if (productTuple[0].id !== product.id) {
                continue;
            }

            // remove product
            if (count === 0) {
                console.log("Remove")
                const products: [Product, number][] = Object.assign([], selectedProducts);
                products.splice(Number(index), 1)
                setSelectedProducts(products)
                break;
            }
            console.log("Update anyway")
            // update count
            const products: [Product, number][] = Object.assign([], selectedProducts);
            products[Number(index)][1] = count
            setSelectedProducts(products)
        }
    }

    const onError = (message: string, duration = 1000) => {
        setError(<IonToast animated={true} duration={duration} message={message} isOpen={true} color={"danger"}/>)
        // make sure that we can reuse our toast
        setTimeout(() => {
            setError(<></>)
        }, duration)
    }

    const onImageChosen = (imageData: string) => setImages([...selectedImages, imageData])

    const checkSubmitButton = () => {
        if (selectedProducts.length === 0) {
            setIsReadyToSubmit(false)
            return
        }

        setIsReadyToSubmit(true)
    }
    useEffect(checkSubmitButton, [selectedProducts])

    const onImageDelete = (index: number) => {
        const images = Object.assign([], selectedImages);
        images.splice(index, 1)
        setImages(images)
    }

    const onSubmit = async () => {
        if (isReadyToSubmit === false) {
            return
        }
        const campainId = state.campaign?.id;
        try {
            setShowLoading(true)
            await mutate({
                variables: {
                    files: selectedImages,
                    fromDate: fromDate,
                    tillDate: toDate,
                    campaignId: campainId,
                    lineItems: selectedProducts.map((productTuple) => {
                        return { productId: productTuple[0].id, count: productTuple[1] }
                    })
                }
            })
        } catch (error) {
            setShowLoading(false)
            onError("Beim Senden der Daten ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal.", 3000)
        }
    }
    return (
        <IonPage>
            <IonContent>
            <Header
                key="campaign-sale"
                showInfoButton={false}
                enableBackNavigation={true}
                compactMode={true}
                label={title}>
                    <></>
            </Header>

                <div className="campaign-sale-container">
                    <div className="campaign-sale-title">
                        <Text color={Colors.oceanBlue} weight={TextWeight.light} size={TextSize.xl} style={{letterSpacing:0}}>
                            Nachweis einreichen
                        </Text>
                    </div>
                    <div>
                    <SegmentedText top={40} bottom={40}>
                        <div className="campaign-sale-segmend">
                        <Text style={{marginRight:5}}>
                            Für:
                        </Text>
                        <div className="campaign-sale-segmented-title">
                        <Text >
                            {state.campaign?.title}
                        </Text>
                        <Text style={{marginLeft:5}}>
                            {state.campaign?.subtitle}
                        </Text>
                        </div>
                        </div>
                    </SegmentedText>
                    </div>
                    <div style={{marginLeft:20, marginTop:20}}>
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            1. Zeitraum für Nachweis angeben:
                        </Text>
                    </div>


                    <DateRangeSelect rangeMin={state.campaign?.startsAt} rangeMax={state.campaign?.expiresAt} fromDate={fromDate} toDate={toDate} onChangedFromDate={onChangedFromDate} onChangedToDate={onChangedToDate}></DateRangeSelect>

                    <div style={{marginLeft:20, marginTop:50, marginBottom:10}}>
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            2. Produkte aus Abverkauf / Empfehlung:
                        </Text>
                        {selectedProducts.length === 0? (
                        <div style={{marginTop:20}}>
                        <Text weight={TextWeight.semibold} color={Colors.brownishGrey} >
                            Benenne bitte das abverkaufte Cetaphil<sup>®</sup> Produkt:
                        </Text>
                        </div>
                        ) : (<></>)
                        }
                    
                    </div>
                        {
                        selectedProducts.map((product) => (
                            <div key={product[0].id}>
                                <ProductSelectionTile product={product[0]} onProductCountChanged={onProductChanged}/>
                            </div>
                        ))
                        }
                        {
                        (selectedProducts.length > 0) ? (
                            <div className="campaign-sale-more-products-text">
                                <Text weight={TextWeight.semibold} color={Colors.brownGrey}>Weiteres Produkt hinzufügen:</Text>
                            </div>
                        ) : (<></>)
                        }

                    <div className="campaign-sale-pzn-product-selection-container">
                        <PZNPicker onProductSelected={onProductSelected} onError={onError}></PZNPicker>

                        <div className="campaign-sale-product-selection-container">
                            <div className="campaign-sale-product-selection-seperator"></div>
                            <Text size={TextSize.s} weight={TextWeight.semibold} style={{marginLeft:20, marginRight:20}}>ODER</Text>
                            <div className="campaign-sale-product-selection-seperator"></div>
                        </div>

                        <ProductChooser onProductSelected={onProductSelected} onError={onError} />

                    </div>

                 

                    <div style={{marginLeft:20, marginTop:20, marginBottom:10}}>
                        <Text weight={TextWeight.semibold} size={TextSize.s} color={Colors.oceanBlue} style={{textTransform:"uppercase"}}>
                            3. Bildnachweis aus Kassensystem
                        </Text>
                    </div>
                    <div>
                        {
                            selectedImages.map((image, index) => (
                                <ImageThumbnail key={index} imageData={image} index={index} onDeleteClicked={onImageDelete} />
                            ))
                        }
                        {
                        (selectedImages.length > 0) ? (
                            <div className="campaign-sale-more-products-text">
                                <Text weight={TextWeight.semibold} color={Colors.brownGrey}>Weiteres Foto hinzufügen:</Text>
                            </div>
                        ) : (<></>)
                        }
                    </div>

                    <div className="campaign-sale-pzn-product-selection-container">
                        <ProductImagePicker onImageChosen={onImageChosen} />
                    </div>

                </div>


                <div className="campaign-sale-submit-button-container">
                <Button kind={isReadyToSubmit ? ButtonType.positiv : ButtonType.deactivated} text="Nachweis senden" top={20} bottom={20} onButtonClicked={onSubmit}></Button>
                </div>
                { error }

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Daten werden übertragen...'}
                />
            </IonContent>
        </IonPage>
    )
}

export default CampaignSale