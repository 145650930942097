import React, { CSSProperties } from 'react';
import Colors from '../../Colors';
import './Text.css'

export enum TextSize {
    xxs = "10px",
    xs = "13px",
    s = "14px",
    m = "18px",
    l = "20px",
    xl = "30px",
    xxl = "32px"
}

export enum TextWeight {
    light = 300,
    normal = "normal",
    bold = "bold",
    semibold = 600,
    extraBold = 900,
    iatlic = 1
}

interface TextProps {
    size?: TextSize,
    color?: Colors,
    weight?: TextWeight,
    children?: React.ReactNode,
    style?: CSSProperties
}

const Text = ({size = TextSize.m, color = Colors.brownishGrey, weight = TextWeight.normal, children, style} : TextProps) => (
    <span className="text" style={ {color: color, fontSize: size, fontWeight: weight, ...style} } >
    { typeof children === "string"? (<div dangerouslySetInnerHTML={{__html:
        children = children.replace("<p>", "").replace("</p>", "")
    }}></div> ) : ( children)
    }
    </span>
)

export default Text
