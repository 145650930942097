import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Campaign, CampaignCategory } from '../../models/models'
import { IonPage, IonContent, IonSpinner } from '@ionic/react'
import Header from '../../components/shared/Header'
import Text, { TextWeight, TextSize } from '../../components/shared/Text'
import SegmentedText from '../../components/shared/SegmentedText'
import Button, { ButtonType } from '../../components/shared/Button'
import "./CampaignDetails.css"
import { GraphQLError } from 'graphql'
import { Link } from 'react-router-dom'
import client from '../../api/client'
import { GET_CAMPAIGN_CATEGORY } from '../../api/queries'
import Colors from '../../Colors'

interface CampaignDetailsRouterProps {
  id: string;
}

interface CampaignDetailsProps extends RouteComponentProps<CampaignDetailsRouterProps> {
  location: any
}

interface CampaignDetailsState {
  campaign?: Campaign
  errors?: ReadonlyArray<GraphQLError>
  loading: boolean
}

interface CampaignVars {
  id: string;
}

const CampaignDetails = ({match, location}: CampaignDetailsProps) => {
  const id = match.params.id;
  let title = ""
  if (location.state) {
    title = location.state.title
  }

  const [state, setState] = useState<CampaignDetailsState>({loading: true, errors: undefined, campaign: undefined})

  async function loadCampaignDetails() {

    try {
      const { loading, errors, data } = await client.query({
        query: GET_CAMPAIGN_CATEGORY,
        variables: { id }
      })

      let campaignCategory: (CampaignCategory|undefined) = undefined
      if (data) {
        campaignCategory = data?.campaignCategories.length > 0 ? data?.campaignCategories[0] : undefined;
      }

      let campaign: (Campaign|undefined) = undefined
      if (campaignCategory) {
        campaign = campaignCategory?.campaigns.length > 0 ? campaignCategory?.campaigns[0] : undefined;
      }
      
      setState({ campaign, errors, loading })
    } catch {
      setState({...state, errors: [new GraphQLError("Error while Loading")]})
    }
  }

  const startLoading = () => {
    loadCampaignDetails()
  }

  useEffect(startLoading, [id])

  return (
    <IonPage>
      <IonContent>
        <Header
          key="campaign-listing"
          showInfoButton={false}
          enableBackNavigation={true}
          compactMode={true}
          label={title}>
              {
                state.loading? (
                  <div className="spinner">
                    <IonSpinner name="dots" />
                  </div>
                ) : (
                  <div className="header-image">
                    <img src={state.campaign?.teaserImage} alt="" />
                  </div>
                )
              }
        </Header>
        {
          state.loading ? (
            <div>
              <div className="spinner">
                <IonSpinner name="dots" />
              </div>
            </div>
          ) : <></>
        }
        {
          state.errors ? (
            <span>
              Fehler beim Laden
            </span>) : <></>
        }

        {
          state.campaign ? (
            <>
            <div className="details-container">
              <div className="details-heading">
                <Text weight={TextWeight.semibold} >
                  Mit jedem dokumentierten Abverkauf sammelst Du:
                </Text>
              </div>
              <SegmentedText>
                <Text weight={TextWeight.semibold} color={Colors.oceanBlue} size={TextSize.l} style={{alignSelf:"center", justifyContent:"space-between", marginTop:20, marginBottom:20, textTransform:"uppercase"}}>
                  {state.campaign.credits} Prämien-Punkte
                </Text>
              </SegmentedText>

              <div className="details-descriptionText">
                <Text >
                {state.campaign.description}
                </Text>
              </div>

              <div className="details-button">
                <Link className="link-reset" key="campaign-sale"
                to={{
                  pathname: `/campaign-sale/${state.campaign.id}`,
                  state: {title: "Abverkauf / Empfehlung"}
                }}>
                  <Button kind={ButtonType.positiv} text="Nachweis einreichen" top={15} bottom={15} onButtonClicked={() => {

                  }}/>
                </Link>
              </div>
            </div>
            </>
          ) : (
            <></>
          )
        }
      </IonContent>
    </IonPage>
  )
}

export default CampaignDetails