import React from 'react'
import Text from "../shared/Text"
import "./DateSelect.css"
import { IonDatetime } from '@ionic/react';
import moment from 'moment'


interface DateSelectProps {
    min? : string
    max? : string
    value : string
    onDateChanged : Function
}




const DateSelect = ({min, max, value, onDateChanged} : DateSelectProps) => {

    const monthShortNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    // const [selectedDate, setSelectedDate] = useState<string>('2020-02-21');
    return (
        
        <div className="date-select-container">
            <div className="date-select-text">
                <Text > 
                    { moment(value).format('DD.MM.YY') }
                </Text>
                
            </div>
            
            <div className="date-select-arrow">

            <IonDatetime doneText="Speichern" cancelText="Abbrechen" displayFormat="DD.MMM.YYYY" 
            monthShortNames={monthShortNames}
            value={value} onIonChange={e => onDateChanged(e.detail.value!)} min={min} max={max}
            >
          </IonDatetime>

            </div>
        </div>


    ); 

    

}

export default DateSelect